import React from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { TableCell, TableRow, Grid } from '@material-ui/core';
import moment from 'moment';

import API from 'apis/API';
import Routes from 'router/Routes';
import Messages from 'utils/Messages';

import PaginatedTable from 'components/Table/PaginatedTable';
import { Visible, VisibleOff } from 'components/Text/Visible';
import FillButton from 'components/Buttons/FillButton';
import { EditIconButton, DeleteIconButton } from 'components/Buttons/IconButtons';

const Training = props => {
    const history = useHistory();
    const { addToast } = useToasts();
    const { title } = props;

    const columns = [
        {
            title: 'ID',
            name: 'id',
        },
        {
            title: 'Nazwa',
            name: 'title',
        },
        {
            title: 'Kategoria',
            name: 'category',
        },
        {
            title: 'Data szkolenia',
            name: 'start_date',
        },
        {
            title: 'Data wpisu',
            name: 'updated_at',
        },
        {
            title: 'Wpis widoczny',
            name: 'visible',
            filters: [
                { title: 'Wszystkie', value: 2 },
                { title: 'Tylko widoczne', value: 1 },
                { title: 'Tylko ukryte', value: 0 }
            ],
            width: 135
        },
        {
            title: 'Akcje',
            name: 'action',
            width: 100
        },
    ];

    const buttons = [
        {
            component: FillButton,
            title: 'Dodaj wydarzenie',
            onClick: () => history.push(Routes.Trainings.Create)
        },
    ];

    const handleDelete = id => {
        API.trainings.delete(id).then(res => {
            let message = res.data.message;
            API.files.delete(id, 'trainings').then(res => {
                addToast(message, { appearance: 'success', autoDismissTimeout: 3000, autoDismiss: true });
            });
        });
    }

    const renderRow = (item, index) => (
        <TableRow key={index} >
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.title?.pl}</TableCell>
            <TableCell>{item.category.title}</TableCell>
            <TableCell>{moment(item.start_date).format('DD.MM.YYYY HH:mm')}</TableCell>
            <TableCell>{moment(item.updated_at).format('DD.MM.YYYY HH:mm')}</TableCell>
            <TableCell>{parseInt(item.is_visible) ? <Visible /> : <VisibleOff />}</TableCell>

            <TableCell>
                <Grid container>
                    <Grid item xs={6}>
                        <EditIconButton onClick={() => history.push(Routes.Trainings.Edit(item.id))} />
                    </Grid>
                    <Grid item xs={6}>
                        <DeleteIconButton onClick={() => handleDelete(item.id)} title={Messages.Delete} />
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );

    return (
        <PaginatedTable
            title={title}
            buttons={buttons}
            endpoint={API.trainings.index}
            columns={columns}
            renderRow={renderRow}
        />
    );
};

export default Training;
