import React, {useState, useEffect, useCallback} from 'react';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/styles';
import {useMediaQuery} from '@material-ui/core';
import API from "../apis/API";
import Topbar from "./Main/Topbar";
import Sidebar from "./Main/Sidebar";

const useStyles = makeStyles(theme => ({
	root: {
		paddingTop: 56,
	},
	shiftContent: {
		paddingLeft: 300,
	},
	topbar_max: {
		visibility: 'visible',
		width: '100%',
	},
	topbar_min: {
		transform: 'translateX(0)',
		visibility: 'visible',
	},
	content: {
		position: 'relative',
		height: 'calc(100vh - 56px)',
		overflow: 'scroll',
		backgroundColor: theme.palette.background.default,
	},
	mainContainer: {
		padding: theme.spacing(6, 4, 0, 4),
	},
}));

const Main = props => {
	const {children} = props;
	const classes = useStyles();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
		defaultMatches: true
	});
	const [openSidebar, setOpenSidebar] = useState(true);
	const [title, setTitle] = useState('');

	const handleSidebarOpen = () => setOpenSidebar(true);

	const handleSidebarClose = useCallback(() => {
		setOpenSidebar(false);
	}, []);

	useEffect(() => {
		API.auth.validateToken().then(res => {
			let user = res.data?.user;
			setTitle(user?.name);
		});
	}, []);

	useEffect(() => {
		if (isDesktop === false) {
			setOpenSidebar(false);
		}
	}, [isDesktop]);

	return (
		<div className={clsx({
            [classes.root]: true,
            [classes.shiftContent]: openSidebar && isDesktop
		})}>
			<Topbar
                onSidebarOpen={handleSidebarOpen}
                onSidebarClose={handleSidebarClose}
                openSidebar={openSidebar}
				className={!openSidebar || !isDesktop ? classes.topbar_max : classes.topbar_min}
                title={title}
            />
			<Sidebar
				onClose={handleSidebarClose}
				open={openSidebar}
				variant={isDesktop ? 'persistent' : 'temporary'}
			/>
			<main className={classes.content}>
				<div className={classes.mainContainer}>
					{children}
				</div>
			</main>
		</div>
	);
};

export default Main;
