import React, { useState, useEffect } from 'react';
import {
	Grid, Card
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';

import Routes from 'router/Routes';
import API from 'apis/API';
import Validator, { Required, ValidISOString, BeforeOrEqual, AfterOrEqual, RequiredIf, FileSizeLimit, validate } from 'utils/Validator';
import Messages from 'utils/Messages';

import BackToMain from 'components/Buttons/BackToMain';
import SaveButton from 'components/Buttons/SaveButton';
import DeleteButton from 'components/Buttons/DeleteButton';
import Progress from 'components/Main/Progress';
import Title from 'components/Text/Title';
import { Visible, VisibleOff } from 'components/Text/Visible';
import Html from 'components/Form/Html';
import FormInput from 'components/Form/FormInput';
import Checkbox from 'components/Form/Checkbox';
import Radio from 'components/Form/Radio';
import Dropdown from 'components/Form/PlainInputs/Dropdown';
import DateInput from 'components/Form/DateInput';
import TimeInput from 'components/Form/TimeInput';
import ThumbnailPicker from 'components/Form/ThumbnailPicker';
import FilesPicker from 'components/Form/FilesPicker';

const useStyles = makeStyles((theme) => ({
	card: {
		marginBottom: theme.spacing(4)
	},
	cardLeft: {
		paddingRight: theme.spacing(20)
	},
	label: {
		fontSize: 14,
		fontWeight: 700,
	},
	row: {
		display: 'flex',
		alignItems: 'center'
	},
}));

const CalendarForm = props => {
	const { title, history } = props;
	const id = props.match.params.id;
	const classes = useStyles();
	const { addToast } = useToasts();
	const visibleList = [
		{
			name: 1,
			label: <Visible />
		},
		{
			name: 0,
			label: <VisibleOff />
		},
	];
	const [reminders, setReminders] = useState(null);
	const [data, setData] = useState({
		title: { pl: '', en: '' },
		description: { pl: '', en: '' },
		start_date: moment().format('YYYY-MM-DD HH:mm'),
		end_date: moment().format('YYYY-MM-DD HH:mm'),
		is_reminder: false,
		reminder_id: 1,
		is_full_day: false,
		is_visible: 1,
	});
	const breadcrumbList = [
		{
			type: 'route',
			route: Routes.Calendar.List,
			label: 'Kalendarz'
		},
		{ label: data?.title?.pl }
	];
	const [errors, setErrors] = useState(null);
	const [progressStatus, setProgressStatus] = useState(false);
	const [files, setFiles] = useState({
		image: null,
		main: null,
	});
	const Validators = {
		title: new Validator(Required),
		description: new Validator(Required),
		start_date: new Validator(Required, ValidISOString(), BeforeOrEqual('end_date', Messages.BeforeEndDate)),
		end_date: new Validator(RequiredIf('is_full_day', false, undefined, ''), ValidISOString(Messages.InvalidTime), AfterOrEqual('start_date', Messages.AfterStartDate)),
	};
	const FileValidators = {
		image: new Validator(FileSizeLimit),
		main: new Validator(FileSizeLimit),
	};

	useEffect(() => {
		API.calendar.getReminders().then(data => setReminders(data));
		if (!id) return;
		API.calendar.show(id).then(res => {
			if (!res?.data) return;
			let _data = {
				...res.data,
				is_reminder: parseInt(res.data?.is_reminder),
				reminder_id: parseInt(res.data?.reminder_id),
				is_full_day: parseInt(res.data?.is_full_day),
				is_visible: parseInt(res.data?.is_visible),
			}
			setData(_data);
			setFiles({ ...res.data?.files, image: res.data?.files?.image?.[0] });
		});
	}, [id]);

	const handleChange = e => {
		const target = e.target;
		const locale = target.dataset?.locale;
		const name = target.name;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		value = target.type === 'radio' ? parseInt(value) : value;

		setData(prev => {
			prev[name] = locale
				? { ...prev[name], [locale]: value }
				: value;
			return { ...prev }
		})
	}

	const handleFileChange = e => {
		const target = e.target;
		const name = target.name;
		const value = target.value;
		setFiles(prev => {
			prev[name] = value;
			return { ...prev }
		});
	}

	const handleSave = () => {
		let _errors = validate(data, Validators);
		let fileErrors = validate(files, FileValidators);

		setErrors({ ..._errors, file: fileErrors });
		if (_errors || fileErrors) return;

		setProgressStatus(true);
		(id ? API.calendar.update : API.calendar.create)(data, id)
			.then(res => {
				const newId = res?.data?.id;
				if (!newId) {
					setProgressStatus(false);
					addToast(res.message, { autoDismissTimeout: 3000, autoDismiss: true, appearance: 'error' });
				}

				API.files.upload(files, newId, 'calendar').then(res => {
					setProgressStatus(false);
					addToast(Messages.SuccessResponse, { autoDismissTimeout: 3000, autoDismiss: true, appearance: 'success' });
					setTimeout(() => {
						history.push(Routes.Calendar.List);
					}, 300);
				});
			}).catch(err => setProgressStatus(false));
	}

	const handleDelete = () => {
		setProgressStatus(true);
		API.calendar.delete(id).then(res => {
			let message = res.data.message;
			API.files.delete(id, 'calendar').then(res => {
				setProgressStatus(false);
				addToast(message, { appearance: 'success', autoDismissTimeout: 3000, autoDismiss: true });
				setTimeout(() => {
					history.push(Routes.Calendar.List);
				}, 300);
			});
		});
	}

	return (
		<>
			<Grid container justifyContent="space-between" >
				<BackToMain breadcrumbList={breadcrumbList} title={`Wróć do listy`} onClick={() => history.push(Routes.Calendar.List)} />
			</Grid>
			<Title value={title} />
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<Card className={`${classes.card} ${classes.cardLeft}`}>
						<Title value={`Dane podstawowe`} />
						<FormInput
							title={'Tytuł wydarzenia'}
							name={'title'}
							value={data.title}
							onChange={handleChange}
							error={errors?.title}
							translatable
						/>
					</Card>
					<Card className={`${classes.card} ${classes.cardLeft}`}>
						<Title value={`Opis`} />

						<Grid item xs={12}>
							<Html
								name={'description'}
								value={data.description}
								onChange={handleChange}
								error={errors?.description}
								translatable
							/>
						</Grid>
					</Card>
					<Card className={`${classes.card} ${classes.cardLeft}`}>
						<Title value={`Szczegóły wydarzenia`} />
						<Grid container spacing={3}>
							<Grid item xs={12} md={4} container alignItems="center"><label className={classes.label}>Data i czas</label></Grid>
							<Grid item xs={12} md={8} container>
								<Grid item xs={1} container justifyContent="center" style={{ margin: 'auto' }}><p>od</p></Grid>
								<Grid item xs={5} container>
									<Grid item xs={data.is_full_day ? 12 : 6}>
										<DateInput
											name={'start_date'}
											value={data.start_date}
											onChange={handleChange}
											error={errors?.start_date}
										/>
									</Grid>
									{!data.is_full_day &&
										<Grid item xs={6}>
											<TimeInput
												name={'start_date'}
												value={data.start_date}
												onChange={handleChange}
											/>
										</Grid>
									}
								</Grid>
								<Grid item xs={1} container justifyContent="center" style={{ margin: 'auto' }}><p>do</p></Grid>
								<Grid item xs={5} container>
									<Grid item xs={data.is_full_day ? 12 : 6}>
										<DateInput
											name={'end_date'}
											value={data.end_date}
											onChange={handleChange}
											error={errors?.end_date}
										/>
									</Grid>
									{!data.is_full_day &&
										<Grid item xs={6}>
											<TimeInput
												name={'end_date'}
												value={data.end_date}
												onChange={handleChange}
											/>
										</Grid>
									}
								</Grid>
							</Grid>
						</Grid>
						<Grid container spacing={3}>
							<Grid item xs={12} md={4} />
							<Grid item xs={12} md={8}>
								<Checkbox
									name={'is_full_day'}
									title={'Wydarzenie całodniowe'}
									value={data.is_full_day}
									onChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={3}>
							<Grid item xs={12} md={4} />
							<Grid item xs={12} md={8}>
								<Checkbox
									name={'is_reminder'}
									title={'Przypomnienie'}
									value={data.is_reminder}
									onChange={handleChange}
								/>
							</Grid>
						</Grid>
						{!!data.is_reminder &&
							<Grid container spacing={3}>
								<Grid item xs={12} md={4}></Grid>
								<Grid item xs={12} md={8}>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<Dropdown
												name={'reminder_id'}
												value={data.reminder_id}
												options={reminders}
												onChange={handleChange}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						}
					</Card>
					<Card className={`${classes.card} ${classes.cardLeft}`}>
						<Title value={`Załączniki`} />
						<FilesPicker
							name={'main'}
							value={files.main}
							onChange={handleFileChange}
							error={errors?.file?.main}
							multiple
						/>
					</Card>
				</Grid>
				<Grid item xs={3}>
					<Card className={classes.card}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<SaveButton
									onClick={handleSave}
									saving={progressStatus} />
							</Grid>
							<Grid item xs={6}>
								<DeleteButton
									title={Messages.Delete}
									handleDelete={handleDelete} />
							</Grid>
						</Grid>
					</Card>
					<Card className={classes.card}>
						<Grid container spacing={2} alignItems="center">
							<Grid item xs={4} className={classes.label}>
								Wpis widoczny
							</Grid>
							<Grid item xs={8}>
								<Radio
									title="Wpis widoczny"
									name="is_visible"
									value={data.is_visible}
									onChange={handleChange}
									list={visibleList}
									className={classes.radio}
								/>
							</Grid>
						</Grid>
					</Card>
					<Card>
						<Grid className={classes.label} style={{ marginBottom: 8 }}>Obrazek wyróżniający</Grid>
						<ThumbnailPicker
							name={`image`}
							editLabel="Edytuj obrazek"
							deleteLabel="Usuń obrazek"
							value={files.image}
							onChange={handleFileChange}
							error={errors?.file?.image}
						/>
					</Card>
				</Grid>
			</Grid>
			<Progress status={progressStatus} />
		</>
	);
};

export default CalendarForm;
