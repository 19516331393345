import React, { useState, useEffect } from 'react';
import {
	Grid, Card
} from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import { useToasts } from 'react-toast-notifications'

import API from "apis/API";
import Validator, { Required, Email, validate } from "utils/Validator";
import makeFormData from "utils/makeFormData";

import BackToMain from "components/Buttons/BackToMain"
import Title from "components/Text/Title";
import Progress from "components/Main/Progress";
import SaveButton from "components/Buttons/SaveButton";
import FormInput from "components/Form/FormInput";

const useStyles = makeStyles((theme) => ({
	card: {
		marginBottom: theme.spacing(4)
	},
	card_left: {
		paddingRight: theme.spacing(20)
	},
	progressContainer: {
		position: 'absolute',
		top: '50%',
		width: 'calc(100% - 600px)',
		display: 'flex',
		justifyContent: 'center',
	},
	progress: {
		color: theme.palette.pink
	}
}));

const ProfileForm = props => {
	const classes = useStyles();
	const { addToast, removeAllToasts } = useToasts();
	const Validators = {
		email: new Validator(Required, Email),
	};
	const breadcrumbList = [
		{ label: `Twój profil` }
	];

	useEffect(() => {
		API.auth.validateToken().then(res => {
			let _user = res.data.user;
			setUser(prev => ({ ...prev, email: _user?.email }));
		});
	}, []);

	const [progressStatus, setProgressStatus] = useState(false);
	const [errors, setErrors] = useState(null);

	const [user, setUser] = useState({
		email: '',
		old_password: '',
		password: '',
		confirm_password: '',
	});

	const handleChange = e => {
		const target = e.target;
		const name = target.name;
		const value = target.value;

		setUser(prev => {
			prev[name] = value;
			return { ...prev }
		})
	}

	const handleSave = () => {
		let _errors = validate(user, Validators);
		_errors = _errors ? _errors : {};

		if (!user.old_password && user.password) _errors['old_password'] = `Please enter the old password`;
		if (user.old_password && !user.password) _errors['password'] = `Please enter the new password`;
		if (user.password !== user.confirm_password) _errors['confirm_password'] = `Hasło Nie pasuje.`;

		setErrors(_errors);
		if (Object.keys(_errors).length > 0) return;

		setProgressStatus(true);
		removeAllToasts();
		let _user = { ...user };
		delete _user.confirm_password;

		let formData = makeFormData({ user: _user });

		API.auth.update(formData)
			.then(res => {
				res = res.data;
				setProgressStatus(false)
				if (res.code === 400) {
					setErrors({ [res.type]: res.message });
					return;
				}
				setErrors(null);
				setUser(prev => ({...prev, old_password: '', password: '', confirm_password: ''}));
				addToast(res.message, { appearance: 'success', autoDismissTimeout: 3000, autoDismiss: true });
			})
			.catch(() => setProgressStatus(false));
	}

	return (
		<>
			<Grid container justifyContent="space-between" >
				<BackToMain breadcrumbList={breadcrumbList} />
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<Card className={`${classes.card} ${classes.card_left}`}>
						<Grid className={classes.card}>
							<Title value={`Dane podstawowe`} />
							<FormInput
								title={'E-mail'}
								name={'email'}
								value={user.email}
								onChange={handleChange}
								error={errors?.email}
							/>
						</Grid>

						<Title value={`Zmień hasło`} />
						<FormInput
							title={'Obecne hasło'}
							name={'old_password'}
							value={user.old_password}
							onChange={handleChange}
							error={errors?.old_password}
							type={`password`}
						/>
						<FormInput
							title={'Nowe hasło'}
							name={'password'}
							value={user.password}
							onChange={handleChange}
							error={errors?.password}
							type={`password`}
						/>
						<FormInput
							title={'Powtórz nowe hasło'}
							name={'confirm_password'}
							value={user.confirm_password}
							onChange={handleChange}
							error={errors?.confirm_password}
							type={`password`}
						/>
					</Card>
				</Grid>
				<Grid item xs={3}>
					<Card className={classes.card}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<SaveButton
									onClick={handleSave}
									saving={progressStatus} />
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>
			<Progress status={progressStatus} />
		</>
	);
};

export default ProfileForm;
