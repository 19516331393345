import React from 'react';
import API from "../apis/API";

interface Option {
    value: string,
    label: string
}

interface Response {
    slug: string,
    genre: string,
    group_name: string,
    academic_year: string,
    programe: string,
    mode: string,
}

export default function useGroupOptions() {
    const [options, setOptions] = React.useState<Option[]>([]);

    React.useEffect(() => {
        prepareOptions();
    }, []);

    const prepareOptions = async () => {
        let {data} = await API.sots.index();

        if (!data?.length) return;

        let filteredOptions:Option[] = Object.values(data.reduce((accum: {[key: string]: Option}, current: Response) => {
            let optionToSave = current?.programe && current?.group_name && current?.genre && current?.mode;
            let programeValid = String(current?.programe)?.length > 1;
            let id = `${current?.programe}, ${current?.mode}, ${current?.academic_year}`;

            if (optionToSave && programeValid && !accum[id]) {
                accum[id] = {
                    value: id,
                    label: `${current?.programe}(${current?.mode}) | ${current?.academic_year}`,
                };
            }

            return accum;
        }, {}));

        setOptions(filteredOptions);
    };

    return options;
};
