import React from 'react';
import {makeStyles, useTheme} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        color: theme.palette.color,
        fontFamily: 'roboto',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        position: 'relative',
    },
}));

export default function Minimal({children}) {
    const theme = useTheme();
    const classes = useStyles(theme);

    return (
        <div className={classes.root}>
            <main className={classes.content}>{children}</main>
        </div>
    );
};
