import React, { useState, useEffect } from 'react';
import {Grid, Card, Divider} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Delete from '@material-ui/icons/Delete';
import { useToasts } from 'react-toast-notifications';
import API from 'apis/API';
import Validator, { Required, SiteUrl, validate } from 'utils/Validator';
import BackToMain from 'components/Buttons/BackToMain';
import FormInput from 'components/Form/FormInput';
import Title from 'components/Text/Title';
import SaveButton from 'components/Buttons/SaveButton';
import FillButton from 'components/Buttons/FillButton';
import IconButton from 'components/Buttons/IconButtons';
import IconPicker from './IconPicker';
import LinkTitle from './LinkTitle';
import {DndProvider, useDrag, useDrop} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

const useStyles = makeStyles((theme) => ({
	card: {
		paddingRight: theme.spacing(20)
	},
	addLinkButton: {
		'&.MuiButton-contained': {
			margin: 0,
			paddingInline: theme.spacing(5)
		}
	}
}));

const Item = ({
    item,
    i,
    errors,
    handleMediaChange,
    handleMediaDelete,
    handleLinkChange,
    handleLinkDelete,
    classes,
    handleLinkAdd,
    moveItem,
}) => {
    const ref = React.useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: 'item',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current)
                return;

            const dragIndex = item.index;
            const hoverIndex = i;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex)
                return;

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
                return;
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
                return;
            moveItem(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: 'item',
        item: () => {
            return { id: item.id, index: i };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <div style={{opacity: isDragging ? 0.5 : 1}} ref={ref} data-handler-id={handlerId}>
            <Grid container>
                <Grid item xs={1}>
                    <div style={{ paddingTop: 10 }}>{i + 1}.</div>
                </Grid>
                <Grid item xs>
                    <Grid container alignItems="center">
                        <Grid item xs>
                            <FormInput
                                title="Nazwa"
                                name="title"
                                value={item.title}
                                onChange={e => handleMediaChange(e, i)}
                                translatable
                                error={errors?.[i]?.title}
                            />
                        </Grid>
                        <Grid item style={{ marginLeft: 10 }}>
                            <IconButton onClick={e => handleMediaDelete(i)}>
                                <Delete color="action" />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <IconPicker onChange={e => handleMediaChange(e, i)} value={item.icon} />
                    <div style={{ marginTop: 20 }}>
                        {item?.links && item?.links.length > 0 &&
                            <React.Fragment>
                                <Grid container spacing={2}>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs><div>Nazwa linku</div></Grid>
                                    <Grid item xs><div>Link</div></Grid>
                                </Grid>
                                {item?.links.map((link, j) => (
                                    <Grid key={j} container spacing={2} alignItems="center">
                                        <Grid item xs={1}>{j + 1}</Grid>
                                        <Grid item xs>
                                            <LinkTitle
                                                name="title"
                                                value={link.title}
                                                onChange={e => handleLinkChange(e, i, j)}
                                                error={errors?.[i]?.links?.[j]?.title}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <FormInput
                                                name="link"
                                                value={link.link}
                                                onChange={e => handleLinkChange(e, i, j)}
                                                error={errors?.[i]?.links?.[j]?.link}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <IconButton onClick={e => handleLinkDelete(i, j)}>
                                                <Delete color="action" />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                            </React.Fragment>
                        }
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                            <FillButton
                                title="Dodaj link"
                                className={classes.addLinkButton}
                                onClick={e => handleLinkAdd(i)} />
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Divider style={{ marginBlock: 20 }} />
        </div>
    );
};

const SocialForm = props => {
	const { title } = props;
	const classes = useStyles();
	const { addToast } = useToasts();
	const breadcrumbList = [
		{ label: 'Media społecznościowe' }
	];
	const [data, setData] = useState(null);
	const [deletedIds, setDeletedIds] = useState({
		media: [],
		link: []
	});
	const [errors, setErrors] = useState(null);
	const [saving, setSaving] = useState(false);
	const mediaValidators = {
		title: new Validator(Required),
	};
	const linkValidators = {
		title: new Validator(Required),
		link: new Validator(Required, SiteUrl),
	}

	useEffect(() => {
		API.social.index().then(res => {
			setData(res.data.sort((a, b) => (a.order < b.order) ? -1 : 1).map((item, index) => ({...item, order: item.order || index + 1})));
		});
	}, []);

    const moveItem = React.useCallback((dragIndex, hoverIndex) => {
        const itemToRemove = data[hoverIndex];
        const itemToPlace = data[dragIndex];
        const newData = [...data];
        newData[hoverIndex] = itemToPlace;
        newData[dragIndex] = itemToRemove;
        setData(newData.map((item, index) => ({...item, order: index + 1})));
    }, [data]);

    console.log(data)

	const handleMediaAdd = () => {
		setData(prev => {
			if (!prev) prev = [];
			prev.push({
				title: { pl: '', en: '' },
				icon: null,
				links: null,
                order: data.length + 1,
			});
			return [...prev];
		});
	}

	const handleLinkAdd = i => {
		setData(prev => {
			let links = prev[i].links || [];
			links.push({ title: { pl: '', en: '' }, link: '' });
			prev[i].links = links;
			return [...prev];
		});
	}

	const handleMediaChange = (e, i) => {
		const target = e.target;
		const locale = target.dataset?.locale;
		const name = target.name;
		const value = target.value;

		setData(prev => {
			prev[i][name] = locale
				? { ...prev[i][name], [locale]: value }
				: value;
			return [...prev];
		});
	}

	const handleLinkChange = (e, mediaIndex, linkIndex) => {
		const target = e.target;
		const name = target.name;
		const value = target.value;

		setData(prev => {
			prev[mediaIndex].links[linkIndex][name] = value;
			return [...prev];
		});
	}

	const handleMediaDelete = i => {
		setDeletedIds(prev => {
			prev.media.push(data[i]?.id);
			return { ...prev };
		});
		setData(prev => {
			prev.splice(i, 1);
			return [...prev];
		});
	}

	const handleLinkDelete = (mediaIndex, linkIndex) => {
		setDeletedIds(prev => {
			prev.link.push(data[mediaIndex]?.links?.[linkIndex]?.id);
			return { ...prev };
		});
		setData(prev => {
			let links = prev[mediaIndex].links;
			links.splice(linkIndex, 1);
			prev[mediaIndex].links = links;
			return [...prev];
		});
	}

	const handleSave = () => {
		let _errors = null;

		data.map((item, i) => {
			let error = validate(item, mediaValidators);
			if (error) {
				_errors = !!_errors ? _errors : [];
				_errors[i] = error;
			}
			item?.links?.map((link, j) => {
				let linkError = validate(link, linkValidators);
				if (linkError) {
					if (!_errors) {
						_errors = [];
						_errors[i] = {};
					}
					_errors[i].links = _errors[i]?.links || [];
					_errors[i].links[j] = linkError;
				}
			});
		});

		setErrors(_errors);
		if (_errors) return;
		setSaving(true);

		API.social.store(data, deletedIds).then(res => {
			data.map((item, i) => {
				if (item.icon) {
					API.files.upload({ icon: item.icon }, res.data[i], 'social')
				}
			});

			addToast('Saved!', { autoDismissTimeout: 3000, autoDismiss: true, appearance: 'success' });
			setSaving(false);
		}).catch(() => setSaving(false));
	}

	return (
		<DndProvider backend={HTML5Backend}>
			<Grid container justifyContent="space-between" >
				<BackToMain breadcrumbList={breadcrumbList} />
			</Grid>
			<Title value={title} />
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<Card className={classes.card}>
						<Title value="Linki do mediów" />
						{data && data.map((item, i) => (
                            <Item
                                key={i}
                                item={item}
                                i={i}
                                errors={errors}
                                handleMediaChange={handleMediaChange}
                                handleMediaDelete={handleMediaDelete}
                                handleLinkChange={handleLinkChange}
                                handleLinkDelete={handleLinkDelete}
                                classes={classes}
                                handleLinkAdd={handleLinkAdd}
                                moveItem={moveItem}
                            />
						))}
						<FillButton
							title="Dodaj zakłądkę"
							onClick={handleMediaAdd} />
					</Card>
				</Grid>
				<Grid item xs={3}>
					<Card>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<SaveButton
									onClick={handleSave}
									saving={saving} />
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>
        </DndProvider>
	);
};

export default SocialForm;
