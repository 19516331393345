import React from 'react';
import { useHistory } from 'react-router-dom';
import { TableCell, TableRow, Grid } from '@material-ui/core';
import moment from 'moment';
import API from 'apis/API';
import Routes from 'router/Routes';
import PaginatedTable from 'components/Table/PaginatedTable';
import { ViewIconButton, DeleteIconButton } from 'components/Buttons/IconButtons';

const Students = props => {
    const history = useHistory();
    const { title } = props;

    const columns = [
        {
            title: 'Numer albumu',
            name: 'index',
        },
        {
            title: 'Imię i nazwisko',
            name: 'nick_name',
        },
        {
            title: 'Data ostatniego logowania',
            name: 'last_login_date',
        },
        {
            title: 'Akcje',
            name: 'action',
            width: 100
        },
    ];

    const renderRow = (item, index) => (
        <TableRow key={index} >
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.first_name} {item.last_name}</TableCell>
            <TableCell>{moment(item.last_login_date).format('DD.MM.YYYY HH:mm')}</TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={6}>
                        <ViewIconButton onClick={() => history.push(Routes.Students.Show(item.id))} />
                    </Grid>
                    <Grid item xs={6}>
                        <DeleteIconButton onClick={() => {}} />
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );

    return (
        <PaginatedTable
            title={title}
            endpoint={API.students.index}
            columns={columns}
            renderRow={renderRow}
        />
    );
};

export default Students;
