import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Breadcrumbs } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  breadcrumb: {
    color: theme.palette.sidebar_title_color,
    fontFamily: 'roboto',
    display: 'flex',
    alignItems: 'center'
  },
  link: {
    color: theme.palette.blue,
    fontSize: '0.8125em',
    cursor: 'pointer',
  },
  typo: {
    color: theme.palette.sidebar_title_color,
    fontSize: '0.8125em',
  }
}));

const TextBreadcrumb = props => {
    const {label} = props;
    const classes = useStyles();
    return (
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
            <Typography color="textPrimary" className={classes.typo}>{label}</Typography>
        </Breadcrumbs>
    );
};

export default TextBreadcrumb;
