import React, { useState } from 'react';
import { Card, Container, makeStyles, TextField, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Button from "@material-ui/core/Button";
import Routes from "router/Routes";
import storage from "utils/Storage";
import API from "../../apis/API";

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        width: '50%',
        marginBottom: theme.spacing(2)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export const LogIn = () => {
    const classes = useStyles();
    const history = useHistory();
    const { addToast, removeAllToasts } = useToasts();
    const [userData, setUserData] = useState({
        email: '',
        password: '',
    });
    const inputFields = [
        { name: 'email', label: 'Email Address' },
        { name: 'password', label: 'Password' },
    ];
    const toastOptions = { appearance: 'error', autoDismissTimeout: 3000, autoDismiss: false };

    const handleChange = e => {
        if(e.keyCode === 13) return logIn();

        const target = e.target;
        const name = target.name;
        const value = target.value;
        setUserData({
            ...userData,
            [name]: value
        });
    }

    const logIn = () => {
        if (!userData.email.length && !userData.password.length) {
            addToast(<label>Proszę wypełnić wszystkie wymagane pola.</label>, toastOptions);
            return;
        }

        API.auth.login(userData).then(res => {
            if (res.data.code === 401) {
                addToast(<label>Wprowadzono nieprawidłowe dane .</label>, toastOptions);
                return
            }

            storage.set('token', res.data.token);
            removeAllToasts();
            history.push(Routes.Students.List)
        }).catch(err => {
            addToast(<label>Wprowadzono nieprawidłowe dane .</label>, toastOptions);
        });
    };

    return (
        <Container component="main" maxWidth="xs">
            <Card className={classes.paper}>
                <img src="/images/logo.png" alt="logo" className={classes.logo} />
                <Typography component="h1" variant="h5">
                    Zaloguj się do panelu administracyjnego
                </Typography>
                <form className={classes.form} noValidate>
                    {inputFields.map(field => (
                        <TextField
                            key={field.name}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id={field.name}
                            label={field.label}
                            name={field.name}
                            type={field.name}
                            autoComplete={field.name}
                            autoFocus={field.name === 'email'}
                            onChange={handleChange}
                            onKeyDown={handleChange}
                            value={userData[field.name]}
                        />
                    ))}

                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={logIn}
                        className={classes.submit}
                    >
                        Zaloguj
                    </Button>
                </form>
            </Card>
        </Container>
    )
}
