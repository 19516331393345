import React, {useState, useEffect} from 'react';
import {
    Grid, Card
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {useToasts} from 'react-toast-notifications'

import Routes from 'router/Routes';
import API from 'apis/API';
import Validator, {Required, SiteUrl, FileSizeLimit, validate} from 'utils/Validator';
import Messages from 'utils/Messages';

import BackToMain from 'components/Buttons/BackToMain';
import SaveButton from 'components/Buttons/SaveButton';
import DeleteButton from 'components/Buttons/DeleteButton';
import Progress from 'components/Main/Progress';
import Title from 'components/Text/Title';
import {Visible, VisibleOff} from 'components/Text/Visible';
import Html from 'components/Form/Html';
import FormInput from 'components/Form/FormInput';
import Radio from 'components/Form/Radio';
import Checkbox from 'components/Form/Checkbox';
import ThumbnailPicker from 'components/Form/ThumbnailPicker';
import FilesPicker from 'components/Form/FilesPicker';
import DateTimeInput from "../../components/Form/DateTimeInput";
import moment from "moment";
import Dropdown from "../../components/Form/PlainInputs/Dropdown";
import Input from "../../components/Form/PlainInputs/Input";
import useGroupOptions from "../../hooks/useGroupOptions";

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 500,
        fontFamily: 'roboto',
        fontSize: '0.875em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    card: {
        marginBottom: theme.spacing(4)
    },
    cardLeft: {
        paddingRight: theme.spacing(20)
    },
    subtitle: {
        fontSize: 14,
        fontWeight: 700,
        marginBottom: theme.spacing(1)
    },
    radio: {
        width: '100%',
        margin: 0,
        alignItems: 'center'
    },
}));

const NewsForm = props => {
    const {title, history} = props;
    const id = props.match.params.id;
    const classes = useStyles();
    const {addToast} = useToasts();
    const visibleList = [
        {
            name: 1,
            label: <Visible/>
        },
        {
            name: 0,
            label: <VisibleOff/>
        },
    ];
    const groupOptions = useGroupOptions();
    const [errors, setErrors] = useState(null);
    const [progressStatus, setProgressStatus] = useState(false);
    const [data, setData] = useState({
        title: {pl: '', en: ''},
        is_promoted: false,
        site_url: '',
        description: {pl: '', en: ''},
        is_visible: 1,
        visible_for: [],
        published_at: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    const [files, setFiles] = useState({
        image: null,
        main: null,
        promotion: null,
        gallery: null,
    });
    const breadcrumbList = [
        {
            type: 'route',
            route: Routes.News.List,
            label: 'Aktualności i komunikaty'
        },
        {
            label: data.title.pl
        }
    ];
    const Validators = {
        title: new Validator(Required),
        description: new Validator(Required),
        site_url: new Validator(Required, SiteUrl),
    };
    const FileValidators = {
        image: new Validator(FileSizeLimit),
        main: new Validator(FileSizeLimit),
        promotion: new Validator(FileSizeLimit),
        gallery: new Validator(FileSizeLimit),
    };

    useEffect(() => {
        if (!id) return;
        API.news.show(id).then(res => {
            if (!res?.data) return;
            setData({
                ...res.data,
                visible_for: JSON.parse(res?.data?.visible_for),
                is_promoted: parseInt(res.data?.is_promoted),
                is_visible: parseInt(res.data?.is_visible)
            });
            setFiles({...res.data?.files, image: res.data?.files?.image?.[0]});
        });
    }, [id]);

    const handleChange = e => {
        const target = e.target;
        const locale = target.dataset?.locale;
        const name = target.name;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        value = target.type === 'radio' ? parseInt(value) : value;

        setData(prev => {
            prev[name] = locale
                ? {...prev[name], [locale]: value}
                : value;
            return {...prev}
        });
    };

    const handleFileChange = e => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setFiles(prev => {
            prev[name] = value;
            return {...prev}
        });
    };

    const handleSave = () => {
        let _errors = validate(data, Validators);
        let fileErrors = validate(files, FileValidators);
        setErrors({..._errors, file: fileErrors});
        if (_errors || fileErrors) return;

        setProgressStatus(true);
        (id ? API.news.update : API.news.create)({
            ...data,
            visible_for: JSON.stringify(data?.visible_for)
        }, id)
            .then(res => {
                const newId = res?.data?.id;
                if (!newId) {
                    setProgressStatus(false);
                    addToast(res.message, {autoDismissTimeout: 3000, autoDismiss: true, appearance: 'error'});
                }

                API.files.upload(files, newId, 'news').then(res => {
                    setProgressStatus(false);
                    addToast(Messages.SuccessResponse, {
                        autoDismissTimeout: 3000,
                        autoDismiss: true,
                        appearance: 'success'
                    });
                    setTimeout(() => {
                        history.push(Routes.News.List);
                    }, 300);
                });
            }).catch(err => setProgressStatus(false));
    };

    const handleDelete = () => {
        API.news.delete(id).then(res => {
            let message = res.data.message;
            API.files.delete(id, 'news').then(res => {
                addToast(message, {appearance: 'success', autoDismissTimeout: 3000, autoDismiss: true});
                setTimeout(() => {
                    history.push(Routes.News.List);
                }, 300);
            });
        });
    };

    return (
        <>
            <Grid container justifyContent="space-between">
                <BackToMain breadcrumbList={breadcrumbList} title={`Wróć do listy aktualności`}
                            onClick={() => history.push(Routes.News.List)}/>
            </Grid>
            <Title value={title}/>
            <Grid container spacing={3}>
                <Grid item xs={9}>
                    <Card className={`${classes.card} ${classes.cardLeft}`}>
                        <Title value={`Dane podstawowe`}/>
                        <FormInput
                            title={'Tytuł aktualności'}
                            name={'title'}
                            value={data.title}
                            onChange={handleChange}
                            error={errors?.title}
                            translatable
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4}/>
                            <Grid item xs={12} md={8}>
                                <Checkbox
                                    name={'is_promoted'}
                                    title={'Wpis wyróżniony'}
                                    value={data.is_promoted}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                        <FormInput
                            title={'Wpis na stronie uczelni'}
                            name={'site_url'}
                            value={data.site_url}
                            onChange={handleChange}
                            error={errors?.site_url}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4} className={classes.title}>
                                <label>Grupy odbiorcow</label>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Dropdown
                                    multiple
                                    customPlaceholder={'Wszystkie'}
                                    name={'visible_for'}
                                    value={data.visible_for || null}
                                    valueField={'value'}
                                    titleField={'label'}
                                    options={groupOptions}
                                    onChange={handleChange}
                                    renderValue={(selected) => {
                                        if (selected.length === 0)
                                            return <em>Wszystkie</em>;

                                        return selected.join(' | ');
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                    <Card className={`${classes.card} ${classes.cardLeft}`}>
                        <Title value={`Opis`}/>

                        <Grid item xs={12}>
                            <Html
                                name={'description'}
                                value={data.description}
                                onChange={handleChange}
                                error={errors?.description}
                                translatable
                            />
                        </Grid>
                    </Card>
                    <Card className={`${classes.card} ${classes.cardLeft}`}>
                        <Title value={`Załączniki`}/>
                        <FilesPicker
                            name={'main'}
                            value={files.main}
                            onChange={handleFileChange}
                            error={errors?.file?.main}
                            multiple
                        />
                        <FilesPicker
                            name={'promotion'}
                            value={files.promotion}
                            onChange={handleFileChange}
                            title={`Film promujący`}
                            buttonTitle={`Dodaj film`}
                            error={errors?.file?.promotion}
                            multiple
                            accept={`video/*`}
                        />
                        <FilesPicker
                            name={'gallery'}
                            value={files.gallery}
                            onChange={handleFileChange}
                            title={`Galeria zdjęć`}
                            buttonTitle={`Dodaj galerię`}
                            error={errors?.file?.gallery}
                            multiple
                            accept={`image/*`}
                            isPreview
                        />
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.card}>
                        <Grid container spacing={2}>
                            <Grid item xs={id ? 6 : 12}>
                                <SaveButton
                                    onClick={handleSave}
                                    saving={progressStatus}/>
                            </Grid>
                            {id && (
                                <Grid item xs={6}>
                                    <DeleteButton
                                        title={Messages.Delete}
                                        handleDelete={handleDelete}/>
                                </Grid>
                            )}
                        </Grid>
                    </Card>
                    <Card className={classes.card}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={4} className={classes.subtitle}>
                                Data publikacji
                            </Grid>
                            <Grid item xs={8}>
                                <DateTimeInput
                                    name={'published_at'}
                                    value={data.published_at}
                                    onChange={handleChange}
                                    error={errors?.published_at}
                                />
                            </Grid>
                            <Grid item xs={4} className={classes.subtitle}>
                                Wpis widoczny
                            </Grid>
                            <Grid item xs={8}>
                                <Radio
                                    title="Wpis widoczny"
                                    name="is_visible"
                                    value={data.is_visible}
                                    onChange={handleChange}
                                    list={visibleList}
                                    className={classes.radio}
                                />
                            </Grid>
                        </Grid>
                    </Card>

                    <Card>
                        <Grid className={classes.subtitle}>Obrazek wyróżniający</Grid>
                        <ThumbnailPicker value={files.image} name={`image`} onChange={handleFileChange}
                                         editLabel="Dodaj obrazek" deleteLabel="Usuń obrazek"/>
                    </Card>
                </Grid>
            </Grid>
            <Progress status={progressStatus}/>
        </>
    );
};

export default NewsForm;
