import React, {useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { FindInPage, Delete, Edit } from '@material-ui/icons';
import DeleteModal from "../Modals&Toasts/DeleteModal";

const useStyles = makeStyles(theme => ({
    button: {
        width: '30px',
        minWidth: '30px',
        padding: theme.spacing(0.5),
    }
}));

const IconButton = props => {
    const { onClick, children } = props;
    const classes = useStyles();

    return (
        <Button variant="outlined" className={classes.button} onClick={onClick}>
            {children}
        </Button>
    );
};

export default IconButton;

export const ViewIconButton = props => {
    const { onClick } = props;

    return (
        <IconButton onClick={onClick}>
            <FindInPage color="action" />
        </IconButton>
    );
};

export const EditIconButton = props => {
    const { onClick } = props;

    return (
        <IconButton onClick={onClick}>
            <Edit color="action" />
        </IconButton>
    );
};

export const DeleteIconButton = props => {
    const { onClick, title, hideModal } = props;
    const [openModal, setOpenModal] = useState(false);
    
    const handleClick = () => {
        if(hideModal) {
            onClick();
        } else {
            setOpenModal(true);
        }
    }

    return (
        <>
            <IconButton onClick={handleClick}>
                <Delete color="action" />
            </IconButton>
            <DeleteModal
                title={title}
                openModal={openModal}
                handleClose={() => setOpenModal(false)}
                handleDelete={onClick}
            />
        </>
    );
};
