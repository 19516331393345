import React from 'react';
import {useHistory} from 'react-router-dom';
import {useToasts} from 'react-toast-notifications';
import {TableCell, TableRow, Grid, Modal, Card} from '@material-ui/core';
import moment from 'moment';

import API from 'apis/API';
import Routes from 'router/Routes';
import Messages from 'utils/Messages';

import PaginatedTable from 'components/Table/PaginatedTable';
import FillButton from 'components/Buttons/FillButton';
import {EditIconButton, DeleteIconButton} from 'components/Buttons/IconButtons';
import {Visible, VisibleOff} from 'components/Text/Visible';
import GoogleMapReact from 'google-map-react';
import Title from "../../components/Text/Title";
import FormInput from "../../components/Form/FormInput";
import SaveButton from "../../components/Buttons/SaveButton";

const defaultProps = {
    center: {
        lat: 52.406105,
        lng: 16.926931
    },
    zoom: 11
};

const Events = props => {
    const history = useHistory();
    const {addToast} = useToasts();
    const {title} = props;
    const [show, setShow] = React.useState(false);

    const columns = [
        {
            title: 'ID',
            name: 'id',
        },
        {
            title: 'Tytuł wydarzenia',
            name: 'title',
        },
        {
            title: 'Autor',
            name: 'author',
        },
        {
            title: 'Data wydarzenia',
            name: 'start_date',
        },
        {
            title: 'Data wpisu',
            name: 'updated_at',
        },
        {
            title: 'Wpis widoczny',
            name: 'visible',
            filters: [
                {title: 'Wszystkie', value: 2},
                {title: 'Tylko widoczne', value: 1},
                {title: 'Tylko ukryte', value: 0}
            ],
            width: 135
        },
        {
            title: 'Akcje',
            name: 'action',
            width: 100
        },
    ];

    const buttons = [
        {
            component: FillButton,
            title: 'Dodaj wydarzenie',
            onClick: () => history.push(Routes.Events.Create)
        },
    ];

    const handleDelete = id => {
        API.events.delete(id).then(res => {
            let message = res.data.message;
            API.files.delete(id, 'events').then(res => {
                addToast(message, {appearance: 'success', autoDismissTimeout: 3000, autoDismiss: true});
            });
        });
    }

    const renderRow = (item, index) => (
        <TableRow key={index}>
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.title?.pl}</TableCell>
            <TableCell>{item.author?.name}</TableCell>
            <TableCell>{moment(item.start_date).format('DD.MM.YYYY HH:mm')}</TableCell>
            <TableCell>{moment(item.updated_at).format('DD.MM.YYYY HH:mm')}</TableCell>
            <TableCell>{parseInt(item.is_visible) ? <Visible/> : <VisibleOff/>}</TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={6}>
                        <EditIconButton onClick={() => history.push(Routes.Events.Edit(item.id))}/>
                    </Grid>
                    <Grid item xs={6}>
                        <DeleteIconButton onClick={() => handleDelete(item.id)} title={Messages.Delete}/>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );

    return (
        <>
            <PaginatedTable
                title={title}
                buttons={buttons}
                endpoint={API.events.index}
                columns={columns}
                renderRow={renderRow}
            />
            <Modal
                open={show}
                onClose={() => setShow(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div style={{
                    background: 'white',
                    padding: 20,
                    width: 500,
                    borderRadius: 5,
                }}>
                    <div style={{display: 'flex', justifyContent: 'center', height: 30, width: '100%'}}>
                        <h4>Ustawienia mapy</h4>
                    </div>
                    <div style={{marginBottom: 30}}>
                        <Card>
                            <Title value={`Dane podstawowe`} />
                            <FormInput
                                title={'Tytuł aktualności'}
                                name={'title'}
                            />
                            <FormInput
                                title={'Wpis na stronie uczelni'}
                                name={'site_url'}
                            />
                        </Card>
                    </div>
                    <div>
                        <SaveButton />
                    </div>
                    <div style={{
                        height: 500,
                        width: '100%'
                    }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{key: "AIzaSyBdIoHt7iO7wSyiWohlvX3dGOUeDWw2y9s"}}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                            onClick={e => console.log(e)}
                        />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Events;
