import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Routes from "router/Routes";
import DeleteButton from "components/Buttons/DeleteButton";
import Title from "components/Text/Title";
import TextItem from "components/Text/TextItem";
import BackToMain from "components/Buttons/BackToMain";
import API from "apis/API";

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: theme.spacing(5)
    },
    title: {
        fontFamily: 'roboto',
        marginBottom: theme.spacing(2),
        color: theme.palette.color,
        fontSize: '0.9em',
        alignItems: 'center',
        fontWeight: '700',
    },
    facultyfiled: {
        margin: theme.spacing(1, 0),
    }
}));

const nullProfile = {
    "first_name": "",
    "last_name": "",
    "email": "",
    "group_assigments": []
}

const academicYearLabels = {
    '1': 'rok I',
    '2': 'rok II',
    '3': 'rok III',
    '4': 'rok IV',
    '5': 'rok V',
};

const StudentProfile = props => {
    const { id } = useParams();
    const { history } = props;
    const classes = useStyles();
    const [profile, setProfile] = useState(nullProfile);
    const breadcrumbList = [
        {
            type: 'route',
            route: Routes.Students.List,
            label: 'Lista studentów',
        },
        {
            type: 'text',
            label: `${profile?.first_name} ${profile?.last_name}`,
        }
    ]

    useEffect(() => {
        API.students.show(id).then(response => setProfile(response.data)).catch(() => setProfile(null));
        // setProfile({
        //     index: 123456,
        //     first_name: 'John',
        //     last_name: 'Doe',
        //     email: 'admin@gmail.com'
        // })
    }, [id]);


    // if (!profile) {
    //     return (
    //         <>
    //             <Grid container justifyContent="space-between">
    //                 <Breadcrumb list={breadcrumbList} />
    //                 <OutlineButton title={`Wróć do listy studentów`} onClick={() => history.push(Routes.Students.List)} />
    //             </Grid>
    //             {profile === null && <CircularProgress />}
    //         </>
    //     );
    // }

    const groupAssigments = profile?.group_assigments || [];

    const handleDelete = () => {
        // API.Students.delete(id).then(response => setTimeout(function () { history.push(Routes.Students.List); }, 500));
    }

    return (
        <>
            <Grid>
                <BackToMain breadcrumbList={breadcrumbList} title={`Wróć do listy studentów`} onClick={() => history.push(Routes.Students.List)} />

                <Grid container spacing={3}>
                    <Grid item md={9}>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <Card>
                                    <Grid className={classes.container}>
                                        <Title value="Dane studenta" />
                                        <TextItem subtitle="Numer albumu" md={4} value={profile?.id} />
                                        <TextItem subtitle="Imię i nazwisko" md={4} value={`${profile?.first_name} ${profile?.last_name}`} />
                                        <TextItem subtitle="E-mail" md={4} value={profile?.email} />
                                    </Grid>

                                    <Grid className={classes.container}>
                                        <Title value="Kierunki studiów" />
                                        <Grid className={classes.facultyfiled}>
                                            {groupAssigments.map((groupAssigment, index) => (
                                                <Grid key={index} className={classes.container}>
                                                    <Grid container justifyContent="flex-end">
                                                        <Grid item xs={1}>{index + 1}.</Grid>
                                                        <Grid item xs={11}>
                                                            <TextItem subtitle="Kierunek" md={3} value={`${groupAssigment.programe}`} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container justifyContent="flex-end">
                                                        <Grid item xs={11}>
                                                            <TextItem subtitle="Grupa studencka" md={3} value={`ID ${groupAssigment.slug}`} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container justifyContent="flex-end">
                                                        <Grid item xs={11}>
                                                            <TextItem subtitle="Rodzaj studiów" md={3} value={`${groupAssigment.genre}`} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container justifyContent="flex-end">
                                                        <Grid item xs={11}>
                                                            <TextItem subtitle="Tryb studiów" md={3} value={`${groupAssigment.mode}`} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container justifyContent="flex-end">
                                                        <Grid item xs={11}>
                                                            <TextItem subtitle="Rok" md={3} value={`${academicYearLabels[groupAssigment.year]}`} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container justifyContent="flex-end">
                                                        <Grid item xs={11}>
                                                            <TextItem subtitle="Rok akademicki" md={3} value={`${groupAssigment.academic_year}`} />
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={3}>
                        <Card>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <DeleteButton
                                        title="Czy na pewno chcesz usunąć profil studenta z aplikacji?"
                                        handleDelete={handleDelete} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default StudentProfile;
