import React, { useRef } from "react";
import clsx from 'clsx';
import { Grid, List, ListItem, ListItemIcon, ListItemText, Divider } from "@material-ui/core";
import { Delete } from '@material-ui/icons';
import { makeStyles } from "@material-ui/styles";
import FillButton from "../Buttons/FillButton";

const useStyles = makeStyles(theme => ({
    actionItem: {
        marginBottom: '16px',
    },
    filledButton: {
        width: '100%'
    },
    label: {
        fontSize: 14,
        fontWeight: 700,
        marginBottom: theme.spacing(1)
    },
    imageItem: {
        marginRight: 10,
        position: 'relative'
    },
    image: {
        width: 70,
        height: 70,
    },
    deleteButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        color: theme.palette.white,
        backgroundColor: theme.palette.primary.dark,
        cursor: 'pointer',
    },
    error: {
        border: `1px solid ${theme.palette.error.main}`
    },
    errorLabel: {
        fontSize: 12,
        color: theme.palette.error.main,
        margin: theme.spacing(1, 0, 0, 2)
    }
}));

const FilesPicker = props => {
    const { value, name, multiple, isPreview, onChange, title, buttonTitle, xs, labelClassName, error, ...rest } = props;
    const classes = useStyles();
    const input = useRef();
    const files = Array.isArray(value) ? value : (value ? [value] : []);

    const openPicker = () => input.current.click();

    const deleteFile = index => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        onChange?.({
            target: {
                name,
                value: newFiles,
            },
        });
    };

    const change = e => onChange?.({
        target: {
            name,
            value: [...files, ...Array.from(e.target.files)],
        },
    });

    return (
        <Grid item xs={12} className={classes.actionItem}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={3}>
                        <Grid item xs={xs || 3} className={clsx(classes.label, labelClassName)}><label>{title || `Pliki do pobrania`}</label></Grid>
                        <Grid item xs={3}>
                            <FillButton title={buttonTitle || `Dodaj pliki`} className={classes.filledButton} onClick={openPicker} />
                            <input
                                key={Math.random()}
                                ref={input}
                                type={'file'}
                                style={{ display: 'none' }}
                                onChange={change}
                                multiple={multiple}
                                {...rest}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    {!isPreview &&
                        <List>
                            {files.map((file, index) => (
                                <React.Fragment key={index}>
                                    <ListItem button className={clsx({[classes.error]: error?.[file?.size]})}>
                                        <ListItemText primary={file?.original_name || file.name} />
                                        <ListItemIcon>
                                            <Delete onClick={() => deleteFile(index)} />
                                        </ListItemIcon>
                                    </ListItem>
                                    {!!error?.[file?.size] &&
                                        <div className={classes.errorLabel}>{error?.[file?.size]}</div>
                                    }
                                    <Divider />
                                </React.Fragment>
                            ))}
                        </List>
                    }
                    {isPreview &&
                        <Grid container>
                            {files.map((file, index) => (
                                <Grid key={index} item className={classes.imageItem}>
                                    <img
                                        className={classes.image}
                                        src={file && (typeof file === 'string' ? file : file?.url ? file.url : URL.createObjectURL(file))}
                                        onClick={() => { }}
                                        alt={''}
                                    />
                                    <div className={classes.deleteButton}>
                                        <Delete fontSize={`small`} onClick={() => deleteFile(index)} />
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FilesPicker;