import axios, {AxiosInstance} from "axios";
import makeFormData from "utils/makeFormData";

const API = axios.create({
    baseURL: 'https://ump.nfinity.pl/api',
});

/**
 * Auth
 */

const auth = {
    login: (data: any) => API.post(`/auth/login`, data),
    forgot: (email: string) => {
        return API.post(`/auth/forgot`, {
            email: email
        })
            .then(response => {
                return response.data;
            }).catch(error => {
                return error;
            });
    },
    resetPassword: (password: string) => {
        return API.post(`/auth/reset_password`, {
            password: password
        })
            .then(response => {
                return response.data;
            }).catch(error => {
                return error;
            });
    },
    validateToken: () => API.get(`/user/validate_token`),
    update: (data: any) => API.post(`user/update`, data)
};

/**
 * Users
 */
const users = {
    index: (params: object) => API.get('/users', {params}),
    show: (id: number) => API.get(`/users/${id}`).then(res => res.data),
    create: (data: any) => API.post('/users', data).then(res => res.data),
    update: (data: any, id: number) => API.put(`/users/${id}`, data).then(res => res.data),
    delete: (id: number) => API.delete(`/users/${id}`),
    permissions: () => API.get(`/users/permissions`)
};

const students = {
    index: (params: object) => API.get(`/students/index`, {params}),
    show: (id: number) => API.get(`/students/${id}`),
}

const sots = {
    index: () => API.get('/sots/index-back')
};

const news = {
    index: (params: object) => API.get('/news', {params}),
    show: (id: number) => API.get(`/news/${id}`),
    create: (data: any) => API.post('/news', data).then(res => res.data),
    update: (data: any, id: number) => API.put(`/news/${id}`, data).then(res => res.data),
    delete: (id: number) => API.delete(`/news/${id}`),
};

const events = {
    index: (params: object) => API.get('/events', {params}),
    show: (id: number) => API.get(`/events/${id}`),
    create: (data: any) => API.post('/events', data).then(res => res.data),
    update: (data: any, id: number) => API.put(`/events/${id}`, data).then(res => res.data),
    delete: (id: number) => API.delete(`/events/${id}`),
    groups: () => API.get('/events/groups'),
};

const virtualWalk = {
    index: (params: object) => API.get('/virtual_walk', {params}),
    show: (id: number) => API.get(`/virtual_walk/${id}`),
    create: (data: any) => API.post('/virtual_walk', data).then(res => res.data),
    update: (data: any, id: number) => API.put(`/virtual_walk/${id}`, data).then(res => res.data),
    delete: (id: number) => API.delete(`/virtual_walk/${id}`),
    setInfo: (data: any) => API.post('/virtual_walk/info', data).then(res => res.data),
    getInfo: () => API.get('/virtual_walk/info'),
    saveMapSettings: (data: any) => API.post('/virtual_walk/map/update', data),
    getMapSettings: () => API.get('/virtual_walk/map')
};

const trainings = {
    index: (params: object) => API.get('/trainings', {params}),
    show: (id: number) => API.get(`/trainings/${id}`),
    create: (data: any) => API.post('/trainings', data).then(res => res.data),
    update: (data: any, id: number) => API.put(`/trainings/${id}`, data).then(res => res.data),
    delete: (id: number) => API.delete(`/trainings/${id}`),
    getCategories: () => API.get('trainings/categories').then(res => res.data)
};

const calendar = {
    index: (params: object) => API.get('/calendar', {params}),
    show: (id: number) => API.get(`/calendar/${id}`),
    create: (data: any) => API.post('/calendar', data).then(res => res.data),
    update: (data: any, id: number) => API.put(`/calendar/${id}`, data).then(res => res.data),
    delete: (id: number) => API.delete(`/calendar/${id}`),
    getReminders: () => API.get('calendar/reminders').then(res => res.data)
};

const questions = {
    index: (params: any) => API.get('/questions', {params}),
    show: (id: number) => API.get(`/questions/${id}`),
    create: (data: any) => API.post('/questions', data).then(res => res.data),
    update: (data: any, id: number) => API.put(`/questions/${id}`, data).then(res => res.data),
    reorder: (data: any) => API.post('/questions/reorder', data),
    delete: (id: number) => API.delete(`/questions/${id}`),
    setInfo: (data: any) => API.post('/questions/info', data).then(res => res.data),
    getInfo: () => API.get('/questions/info'),
};

const studies = {
    index: (params: object) => API.get('/studies', {params}),
    show: (id: number) => API.get(`/studies/${id}`),
    create: (data: any) => API.post('/studies', data).then(res => res.data),
    update: (data: any, id: number) => API.put(`/studies/${id}`, data).then(res => res.data),
    delete: (id: number) => API.delete(`/studies/${id}`),
    getGroupAssigments: () => API.get('/studies/group_assigments'),
    setInfo: (data: any) => API.post('/studies/info', data).then(res => res.data),
    getInfo: () => API.get('/studies/info'),
};

const health = {
    show: () => API.get(`/health`),
    update: (data: any) => API.put(`/health/${data?.id}`, data).then(res => res.data),
    setInfo: (data: any) => API.post('/health/info', data).then(res => res.data),
    getInfo: () => API.get('/health/info'),
};

const accessibility = {
    show: () => API.get(`/accessibility`),
    update: (data: any) => API.put(`/accessibility`, data).then(res => res.data),
};

const support = {
    show: () => API.get(`/support`),
    update: (data: any) => API.put(`/support`, data).then(res => res.data),
};

const social = {
    index: () => API.get(`/social`),
    store: (data: any, deletedIds: any) => API.post(`/social`, {data, deletedIds}).then(res => res.data),
};

const commonInformation = {
    show: () => API.get(`/common_information`),
    update: (data: any) => API.put(`/common_information`, data).then(res => res.data),
};

/**
 * File
 */

const files = {
    upload: (files: any, ref_id: any, ref_type: any) => {
        let formData = makeFormData({ref_id, ref_type});
        Object.keys(files).forEach(key => {
            if (files[key]) {
                if (Array.isArray(files[key])) {
                    files[key].forEach((file: any, i: number) => {
                        file = file?.id ? JSON.stringify(file) : file;
                        formData.append(`files[${key}][${i}]`, file);
                    });
                } else {
                    let file = files[key];
                    file = file?.id ? JSON.stringify(file) : file;
                    formData.append(`files[${key}][0]`, file);
                }
            }
        });

        return API.post(`files/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    delete: (ref_id: any, ref_type: any) => API.delete(`files/delete/${ref_type}/${ref_id}`)
};

const objects = {
    auth,
    users,
    students,
    sots,
    news,
    events,
    virtualWalk,
    trainings,
    calendar,
    questions,
    studies,
    health,
    accessibility,
    support,
    social,
    commonInformation,
    files,
};

type ApiInterface = AxiosInstance & typeof objects;

Object.assign(API, objects);

export default API as ApiInterface;
