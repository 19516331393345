import React from 'react';
import { Grid } from "@material-ui/core";
import Breadcrumb from "../Main/Breadcrumb";
import OutlineButton from "./OutlineButton";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: theme.spacing(2)
    }
}));

const BackToMain = props => {
    const { title, onClick, breadcrumbList } = props;
    const classes = useStyles();

    return (
        <Grid container justifyContent="space-between" className={classes.container}>
            <Breadcrumb list={breadcrumbList} />
            {title && onClick &&
                <OutlineButton title={title} onClick={onClick} />
            }
        </Grid>
    );
};

export default BackToMain;