import React, { useRef } from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ZipIcon } from 'assets/svg/icons';
import FillButton from 'components/Buttons/FillButton';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    fontFamily: 'roboto',
    fontSize: '0.875em',
    margin: theme.spacing(2, 0, 1)
  },
  border: {
    '&.MuiGrid-item': {
      border: `0.5px solid ${theme.palette.btn_gray}`,
      borderRadius: 2,
      padding: 0,
    }
  },
  button: {
    '&.MuiButton-contained': {
      margin: 0,
      padding: theme.spacing(0, 5)
    }
  },
  file: {
    '&.MuiGrid-item': {
      display: 'flex',
      paddingLeft: theme.spacing(2)
    }
  }
}));

const IconPicker = ({ name = 'icon', value, onChange, ...rest }) => {
  const classes = useStyles();
  const ref = useRef();

  const onAdd = () => ref.current.click();
  const handleChangeImage = e => e.target.files.length > 0 && onChange({
    target: {
      name,
      value: e.target.files[0],
    },
  });

  return (
    <>
      <div className={classes.title}>Ikona</div>
      <Grid container>
        <Grid item className={classes.border} style={{ padding: 5 }}>
          <ZipIcon />
        </Grid>
        <Grid item xs className={clsx(classes.border, classes.file)}>
          <div style={{ flex: 1, marginBlock: 'auto' }}>{value?.name || value?.original_name}</div>
          <FillButton title="Dodaj plik" onClick={onAdd} className={classes.button} />
        </Grid>
      </Grid>
      <input
        ref={ref}
        name={name}
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={handleChangeImage}
        {...rest}
      />
    </>
  );
};

export default IconPicker;