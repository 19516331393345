import { Redirect, Switch, Route } from "react-router-dom";
import React, { useEffect } from "react";
import Routes from "./Routes";
import Main from "../layouts/Main";
import Minimal from "../layouts/Minimal";
import { useToasts } from "react-toast-notifications";
import Students from "../pages/Students/Students";
import StudentsProfile from "../pages/Students/StudentsProfile";
import News from "../pages/News/News";
import NewsForm from "../pages/News/NewsForm";
import Events from "../pages/Events/Events";
import EventsForm from "../pages/Events/EventsForm";
import VirtualWalk from "../pages/VirtualWalk/VirtualWalk";
import VirtualWalkForm from "../pages/VirtualWalk/VirtualWalkForm";
import VirtualWalkInfo from "../pages/VirtualWalk/VirtualWalkInfo";
import Training from "../pages/Training/Training";
import TrainingForm from "../pages/Training/TrainingForm";
import Calendar from "../pages/Calendar/Calendar";
import CalendarForm from "../pages/Calendar/CalendarForm";
import Questions from "../pages/Questions/Questions";
import QuestionsForm from "../pages/Questions/QuestionsForm";
import QuestionsInfo from "../pages/Questions/QuestionsInfo";
import Studies from "../pages/Studies/Studies";
import StudiesForm from "../pages/Studies/StudiesForm";
import StudiesInfo from "../pages/Studies/StudiesInfo";
import HealthForm from "../pages/Health/HealthForm";
import HealthInfo from "../pages/Health/HealthInfo";
import SocialForm from "../pages/Social/SocialForm";
import AccessibilityForm from "../pages/Accessibility/AccessibilityForm";
import SupportForm from "../pages/Support/SupportForm";
import Users from "../pages/Users/Users";
import UserForm from "../pages/Users/UserForm";
import NotFound from "../pages/Other/NotFound";
import { LogIn } from "../pages/Auth/LogIn";
import ProfileForm from "pages/Profile/ProfileForm";
import MapSettings from "../pages/VirtualWalk/MapSettings";
import CommonInformation from "../pages/CommonInformation/CommonInformation";

const ComponentRoutes = [
    {
        component: Students,
        title: 'Lista studentów zarejestrowanych w aplikacji',
        path: Routes.Students.List,
    },
    {
        component: StudentsProfile,
        title: 'Studentów',
        path: Routes.Students.Show(),
    },
    {
        component: News,
        title: 'Lista studentów zarejestrowanych w aplikacji',
        path: Routes.News.List,
    },
    {
        component: NewsForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.News.Create,
    },
    {
        component: NewsForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.News.Edit(),
    },
    {
        component: Events,
        title: 'Lista studentów zarejestrowanych w aplikacji',
        path: Routes.Events.List,
    },
    {
        component: MapSettings,
        title: 'Ustawienia mapy',
        path: Routes.VirtualWalk.MapSettings,
    },
    {
        component: EventsForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Events.Create,
    },
    {
        component: EventsForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Events.Edit(),
    },
    {
        component: VirtualWalk,
        title: 'Lista studentów zarejestrowanych w aplikacji',
        path: Routes.VirtualWalk.List,
    },
    {
        component: VirtualWalkForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.VirtualWalk.Create,
    },
    {
        component: VirtualWalkForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.VirtualWalk.Edit(),
    },
    {
        component: VirtualWalkInfo,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.VirtualWalk.Info,
    },
    {
        component: Training,
        title: 'Lista studentów zarejestrowanych w aplikacji',
        path: Routes.Trainings.List,
    },
    {
        component: TrainingForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Trainings.Create,
    },
    {
        component: TrainingForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Trainings.Edit(),
    },
    {
        component: Calendar,
        title: 'Lista studentów zarejestrowanych w aplikacji',
        path: Routes.Calendar.List,
    },
    {
        component: CalendarForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Calendar.Create,
    },
    {
        component: CalendarForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Calendar.Edit(),
    },
    {
        component: Questions,
        title: 'Lista studentów zarejestrowanych w aplikacji',
        path: Routes.Questions.List,
    },
    {
        component: QuestionsForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Questions.Create,
    },
    {
        component: QuestionsForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Questions.Edit(),
    },
    {
        component: QuestionsInfo,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Questions.Info,
    },
    {
        component: Studies,
        title: 'Lista studentów zarejestrowanych w aplikacji',
        path: Routes.Studies.List,
    },
    {
        component: StudiesForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Studies.Create,
    },
    {
        component: StudiesForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Studies.Edit(),
    },
    {
        component: StudiesInfo,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Studies.Info,
    },
    {
        component: HealthForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Health.Create,
    },
    {
        component: HealthInfo,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Health.Info,
    },
    {
        component: SocialForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Social.Create,
    },
    {
        component: AccessibilityForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Accessibility.Create,
    },
    {
        component: SupportForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Support.Create,
    },
    {
        component: Users,
        title: 'Uźytkownicy systemu',
        path: Routes.Users.List,
    },
    {
        component: UserForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Users.Create,
    },
    {
        component: UserForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Users.Edit(),
    },
    {
        component: ProfileForm,
        title: 'Edytuj/Dodaj nowego uźytkownika',
        path: Routes.Profile.Edit,
    },
    {
        component: CommonInformation,
        title: 'Edytuj nowego uźytkownika',
        path: Routes.CommonInformation.Create,
    },
];

const MinimalLayoutRoutes = [
    {
        component: LogIn,
        title: 'Zaloguj się',
        path: Routes.Auth.login,
    },
];

const MainLayout = () => {
    return (
        <Switch>
            <Main>
                {ComponentRoutes.map((route, index) => (
                    <Route key={index} exact {...route} />
                ))}
            </Main>
        </Switch>
    );
};

const MinimalLayout = () => {
    return (
        <Switch>
            <Minimal>
                {MinimalLayoutRoutes.map((route, index) => (
                    <Route key={index} exact {...route} />
                ))}
            </Minimal>
        </Switch>
    );
};

export default function RoutesRouter() {
    const { removeAllToasts } = useToasts();

    useEffect(() => {
        removeAllToasts();
    }, [removeAllToasts]);

    return (
        <Switch>
            <Redirect exact from="/" to={Routes.Auth.login} />

            <Route path={MinimalLayoutRoutes.map(route => route.path)} component={MinimalLayout} />
            <Route path={ComponentRoutes.map(route => route.path)} component={MainLayout} />

            <Route path={'*'} component={NotFound} />
        </Switch>
    );
}
