import React from 'react';
import {Card, Grid} from "@material-ui/core";
import BackToMain from "../../components/Buttons/BackToMain";
import Routes from "../../router/Routes";
import Title from "../../components/Text/Title";
import SaveButton from "../../components/Buttons/SaveButton";
import {makeStyles} from "@material-ui/styles";
import {useHistory} from "react-router-dom";
import GoogleMapReact from 'google-map-react';
import FormInput from "../../components/Form/FormInput";
import API from "../../apis/API";
import {Latitude} from "../../utils/Validator";
import {useToasts} from "react-toast-notifications";

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(4)
    },
    cardLeft: {
        paddingRight: theme.spacing(20)
    },
    label: {
        fontSize: 14,
        fontWeight: 700,
    },
    radio: {
        width: '100%',
        margin: 0,
        alignItems: 'center'
    },
}));

const defaultProps = {
    center: {
        lat: 52.406105,
        lng: 16.926931
    },
    zoom: 11
};

export default function MapSettings() {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const { addToast } = useToasts();
    const [coords, setCoords] = React.useState({
        lat: '',
        lng: '',
    });
    const [deltaCoords, setDeltaCoords] = React.useState({
        latDelta: 0.5,
        lngDelta: 0.5,
    });

    const breadcrumbList = [
        {
            type: 'route',
            route: Routes.VirtualWalk.List,
            label: 'Lista punktów'
        },
        {
            label: 'Ustawienia mapy'
        }
    ];

    React.useEffect(() => {
        API.virtualWalk.getMapSettings().then(res => {
            setCoords({lat: res?.data?.latitude, lng: res?.data?.longitude});
            setDeltaCoords({latDelta: res?.data?.latitudeDelta, lngDelta: res?.data?.longitudeDelta});
        });
    },[]);

    const saveMapCoords = () => {
        if (!coords?.lng || !coords?.lat || !deltaCoords?.latDelta || !deltaCoords?.lngDelta) {
            addToast('Wypełnij wszystkie pola ustawień karty', {appearance: 'warning', autoDismissTimeout: 3000, autoDismiss: true});
            return;
        }

        setLoading(true);
        API.virtualWalk.saveMapSettings({latitude: coords?.lat, longitude: coords?.lng, latitudeDelta: deltaCoords?.latDelta, longitudeDelta: deltaCoords?.lngDelta}).then(res => {
            let message = res.data.message;
            addToast(message, { appearance: 'success', autoDismissTimeout: 3000, autoDismiss: true });
            setLoading(false);
        });
    }

    return (
        <>
            <Grid container justifyContent="space-between" >
                <BackToMain breadcrumbList={breadcrumbList} title={`Wróć do listy wydarzeń`} onClick={() => history.push(Routes.VirtualWalk.List)} />
            </Grid>
            <Title value={'Ustawienia mapy'} />
            <Grid container spacing={3}>
                <Grid item xs={9}>
                    <Card className={classes.card}>
                        <div style={{
                            height: 700,
                            width: '100%',
                        }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{key: "AIzaSyBdIoHt7iO7wSyiWohlvX3dGOUeDWw2y9s"}}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                                onClick={e => {
                                    console.log(e)
                                    setCoords({lat: e.lat, lng: e.lng})
                                }}
                            />
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.card}>
                        <Grid>
                            <SaveButton saving={loading} onClick={() => saveMapCoords()}/>
                        </Grid>
                        {(!!coords?.lng && !!coords?.lat) && (
                            <Grid>
                                <Grid item style={{paddingTop: 30, paddingBottom: 5}}>
                                    <FormInput
                                        title={'Longitude'}
                                        name={'lng'}
                                        value={coords?.lng}
                                    />
                                </Grid>
                                <Grid item style={{paddingTop: 5, paddingBottom: 30}}>
                                    <FormInput
                                        title={'Latitude'}
                                        name={'lat'}
                                        value={coords?.lat}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Card>
                    <Card className={classes.card}>
                        <Title value={`Wysokość od wybranego punktu`} />
                        <Grid>
                            <Grid item style={{paddingTop: 30, paddingBottom: 5}}>
                                <FormInput
                                    title={'Lng delta'}
                                    name={'lngDelta'}
                                    type="number"
                                    min={0}
                                    max={90}
                                    value={deltaCoords?.lngDelta}
                                    onChange={e => setDeltaCoords({
                                        ...deltaCoords,
                                        [e.target.name]: e.target.value
                                    })}
                                />
                            </Grid>
                            <Grid item style={{paddingTop: 5, paddingBottom: 30}}>
                                <FormInput
                                    title={'Lat delta'}
                                    name={'latDelta'}
                                    type="number"
                                    min={0}
                                    max={90}
                                    value={deltaCoords?.latDelta}
                                    onChange={e => setDeltaCoords({
                                        ...deltaCoords,
                                        [e.target.name]: e.target.value
                                    })}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
