import React from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications"
import {
    TableCell, TableRow, Grid
} from "@material-ui/core";
import API from "apis/API";
import Routes from "router/Routes";
import PaginatedTable from "components/Table/PaginatedTable";
import FillButton from "components/Buttons/FillButton";
import { EditIconButton, DeleteIconButton } from "components/Buttons/IconButtons";

const Users = props => {
    const history = useHistory();
    const { addToast } = useToasts();

    const columns = [
        {
            title: 'ID',
            name: 'id',
        },
        {
            title: 'E-mail',
            name: 'email',
        },
        {
            title: 'Uprawnienia',
            name: 'permissions',
        },
        {
            title: 'Akcje',
            name: 'action',
            width: 100
        },
    ];

    const buttons = [
        {
            component: FillButton,
            title: 'Dodaj użytkownika',
            onClick: () => history.push(Routes.Users.Create)
        }
    ];

    const getPermissionNames = permissions => (
        permissions.map((permission, i) => (
            `${permission.permission.name}${i < permissions.length - 1 && `,`} `
        ))
    )

    const handleDelete = id => {
        API.users.delete(id).then(res => {
            addToast(res.data.message, { appearance: 'success', autoDismissTimeout: 3000, autoDismiss: true });
        });
    }

    const renderRow = (item, index) => (
        <TableRow key={index} >
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.email}</TableCell>
            <TableCell>{getPermissionNames(item?.permissions)}</TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={6}>
                        <EditIconButton onClick={() => history.push(Routes.Users.Edit(item.id))} />
                    </Grid>
                    <Grid item xs={6}>
                        <DeleteIconButton onClick={() => handleDelete(item.id)} title={`Czy na pewno chcesz usunąć tego użytkownika?`} />
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );

    return (
        <PaginatedTable
            buttons={buttons}
            endpoint={API.users.index}
            renderRow={renderRow}
            columns={columns}
        />
    );
};

export default Users;
