import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    filter_container: {
        color: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'roboto',
        fontSize: '0.8750em',

        [theme.breakpoints.up('xs')]: {
            marginRight: '0px',
        },
        [theme.breakpoints.up('md')]: {
            marginRight: '20px',
        },
        '& .MuiFormLabel-root': {
            zIndex: 10,
            fontSize: '1em',
        },
        '& .MuiInputLabel-formControl': {
            transform: 'translate(3px, 26px) scale(1)',
        },
        '& .MuiInputLabel-shrink': {
            transform: 'translate(0, 1.5px) scale(0.75);',
        },
        '& .MuiFormControl-root': {
            marginTop: '-16px'
        },
    },
    filter_input: {
        '& .MuiSelect-root': {
            padding: '10.5px 24px 10.5px 14px',
            border: theme.palette.card_border,
            backgroundColor: theme.palette.black_white,
        },
    },
}));

const FilterInput = props => {
    const { onFilterChange, options, id, label, value: propValue } = props;
    const classes = useStyles();
    
    const [value, setValue] = useState(propValue || '');

    useEffect(() => {
        if (options.filter(option => option.value === value).length === 0 && value !== '') setValue('');
    }, [options]);

    useEffect(() => {
        onFilterChange(value);
    }, [value]);

    return (
        <div className={classes.filter_container}>
            <FormControl>
                <div style={{ fontSize: '1em', opacity: '0', paddingRight: '24px', height: '0px' }}>{label}</div>
                <InputLabel id={`FilterInput-Label-${id}`}>{label}</InputLabel>
                <Select id={`FilterInput-Select-${id}`} className={classes.filter_input} labelId={`FilterInput-Label-${id}`} value={value} onChange={e => setValue(e.target.value)}>
                    {options.map(({ value, label }, index) => (
                        <MenuItem value={value}>{label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

FilterInput.propTypes = {
    handleFilter: PropTypes.func,
    values: PropTypes.object
};

export default FilterInput;

