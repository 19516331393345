import React, { useState, useEffect } from 'react';
import {
	Grid, Card
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useToasts } from 'react-toast-notifications'

import Routes from 'router/Routes';
import API from 'apis/API';
import Validator, { Required, FileSizeLimit, validate } from 'utils/Validator';
import Messages from 'utils/Messages';

import BackToMain from 'components/Buttons/BackToMain';
import SaveButton from 'components/Buttons/SaveButton';
import DeleteButton from 'components/Buttons/DeleteButton';
import Progress from 'components/Main/Progress';
import Title from 'components/Text/Title';
import Html from 'components/Form/Html';
import FormInput from 'components/Form/FormInput';
import FilesPicker from 'components/Form/FilesPicker';

const useStyles = makeStyles((theme) => ({
	card: {
		marginBottom: theme.spacing(4)
	},
	cardLeft: {
		paddingRight: theme.spacing(20)
	},
	label: {
		fontWeight: 500,
		fontFamily: 'roboto',
		fontSize: '0.875em',
		marginBlock: theme.spacing(2)
	},
}));

const QuestionsForm = props => {
	const { title, history } = props;
	const id = props.match.params.id;
	const classes = useStyles();
	const { addToast } = useToasts();
	const [errors, setErrors] = useState(null);
	const [progressStatus, setProgressStatus] = useState(false);
	const [data, setData] = useState({
		title: { pl: '', en: '' },
		answer: { pl: '', en: '' },
	});
	const breadcrumbList = [
		{
			type: 'route',
			route: Routes.Questions.List,
			label: 'Lista pytań'
		},
		{ label: data.title?.pl }
	];
	const [files, setFiles] = useState({
		image: null,
		main: null,
		promotion: null,
		gallery: null,
	});
	const Validators = {
		title: new Validator(Required),
		answer: new Validator(Required),
	};
	const FileValidators = {
		image: new Validator(FileSizeLimit),
		main: new Validator(FileSizeLimit),
		promotion: new Validator(FileSizeLimit),
		gallery: new Validator(FileSizeLimit),
	};

	useEffect(() => {
		if (!id) return;
		API.questions.show(id).then(res => {
			if (!res?.data) return;
			setData(res.data);
			setFiles({ ...res.data?.files, image: res.data?.files?.image?.[0] });
		});
	}, [id]);

	const handleChange = e => {
		const target = e.target;
		const locale = target.dataset?.locale;
		const name = target.name;
		const value = target.value;

		setData(prev => {
			prev[name] = locale
				? { ...prev[name], [locale]: value }
				: value;
			return { ...prev }
		})
	}

	const handleFileChange = e => {
		const target = e.target;
		const name = target.name;
		const value = target.value;
		setFiles(prev => {
			prev[name] = value;
			return { ...prev }
		});
	}

	const handleSave = () => {
		let _errors = validate(data, Validators);
		let fileErrors = validate(files, FileValidators);
		setErrors({..._errors, file: fileErrors});
		if (_errors || fileErrors) return;
		setProgressStatus(true);

		(id ? API.questions.update : API.questions.create)(data, id)
			.then(res => {
				const newId = res?.data?.id;
				if (!newId) {
					setProgressStatus(false);
					addToast(res.message, { autoDismissTimeout: 3000, autoDismiss: true, appearance: 'error' });
				}

				API.files.upload(files, newId, 'questions').then(res => {
					setProgressStatus(false);
					addToast(Messages.SuccessResponse, { autoDismissTimeout: 3000, autoDismiss: true, appearance: 'success' });
					setTimeout(() => {
						history.push(Routes.Questions.List);
					}, 300);
				});
			}).catch(err => setProgressStatus(false));
	}

	const handleDelete = () => {
		setProgressStatus(true);
		API.questions.delete(id).then(res => {
			let message = res.data.message;
			API.files.delete(id, 'questions').then(res => {
				setProgressStatus(false);
				addToast(message, { appearance: 'success', autoDismissTimeout: 3000, autoDismiss: true });
				setTimeout(() => {
					history.push(Routes.Questions.List);
				}, 300);
			});
		});
	}

	return (
		<>
			<Grid container justifyContent="space-between" >
				<BackToMain breadcrumbList={breadcrumbList} title={`Wróć do listy pytań`} onClick={() => history.push(Routes.Questions.List)} />
			</Grid>
			<Title value={title} />
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<Card className={`${classes.card} ${classes.cardLeft}`}>
						<Title value={`Pytanie`} />
						<FormInput
							title={'Tytuł aktualności'}
							name={'title'}
							value={data.title}
							onChange={handleChange}
							error={errors?.title}
							translatable
						/>
						<Grid className={classes.label}>
							<label>Odpowiedź</label>
						</Grid>
						<Grid item xs={12}>
							<Html
								name={'answer'}
								value={data.answer}
								onChange={handleChange}
								error={errors?.answer}
								translatable
							/>
						</Grid>
					</Card>

					<Card className={`${classes.card} ${classes.cardLeft}`}>
						<Title value={`Załączniki`} />
						<FilesPicker
							name={'main'}
							value={files.main}
							onChange={handleFileChange}
							error={errors?.file?.main}
							multiple
						/>
						<FilesPicker
							name={'promotion'}
							value={files.promotion}
							onChange={handleFileChange}
							title={`Film promujący`}
							buttonTitle={`Dodaj film`}
							error={errors?.file?.promotion}
							accept={`video/*`}
							multiple
						/>
						<FilesPicker
							name={'gallery'}
							value={files.gallery}
							onChange={handleFileChange}
							title={`Galeria zdjęć`}
							buttonTitle={`Dodaj galerię`}
							accept={`image/*`}
							error={errors?.file?.gallery}
							multiple
							isPreview
						/>
					</Card>
				</Grid>
				<Grid item xs={3}>
					<Card className={classes.card}>
						<Grid container spacing={2}>
							<Grid item xs={id ? 6 : 12}>
								<SaveButton
									onClick={handleSave}
									saving={progressStatus} />
							</Grid>
							{id &&
								<Grid item xs={6}>
									<DeleteButton
										title={Messages.Delete}
										handleDelete={handleDelete} />
								</Grid>
							}
						</Grid>
					</Card>
				</Grid>
			</Grid>
			<Progress status={progressStatus} />
		</>
	);
};

export default QuestionsForm;
