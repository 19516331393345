import React, { useState, useEffect } from 'react';
import {
	Grid, Card
} from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import { useToasts } from 'react-toast-notifications'

import Routes from "router/Routes";
import API from "apis/API";
import useApiResource from "hooks/useApiResource";
import Validator, { Required, Email, validate } from "utils/Validator";

import BackToMain from "components/Buttons/BackToMain"
import Title from "components/Text/Title";
import Progress from "components/Main/Progress";
import SaveButton from "components/Buttons/SaveButton";
import FormInput from "components/Form/FormInput";
import Checkbox from "components/Form/Checkbox";

const useStyles = makeStyles((theme) => ({
	card: {
		marginBottom: theme.spacing(4)
	},
	card_left: {
		paddingRight: theme.spacing(20)
	},
}));

const UserForm = props => {
	const { history } = props;
	const id = props.match.params.id;
	const classes = useStyles();
	const { addToast, removeAllToasts } = useToasts();
	const [permissionsList] = useApiResource(API.users.permissions);
	const Validators = {
		email: new Validator(Required, Email),
	};

	useEffect(() => {
		if (!id) return;

		API.users.show(id).then(res => {
			setUser(res.user);
			setPermissions(res.permissions);
		});
	}, [id]);

	const [progressStatus, setProgressStatus] = useState(false);
	const [errors, setErrors] = useState(null);

	const [user, setUser] = useState({
		email: '',
		password: '',
		confirm_password: '',
		active: false,
	});
	const [permissions, setPermissions] = useState([]);
	const breadcrumbList = [
		{
			type: 'route',
			route: Routes.Users.List,
			label: 'Lista użytkowników'
		},
		{ label: user?.email }
	];

	const handleChange = e => {
		const target = e.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		setUser(prev => {
			prev[name] = value;
			return { ...prev }
		})
	}

	const handlePermissionChange = (e, permissionId) => {
		setPermissions(prev => {
			const index = prev.indexOf(permissionId);

			e.target.checked
				? prev.push(permissionId)
				: prev.splice(index, 1);
			return [...prev]
		})
	}

	const handleSave = () => {
		const toastOptions = { appearance: 'error', autoDismissTimeout: 3000, autoDismiss: true };
		let _errors = validate(user, Validators);
		
		if((user.password || user.confirm_passwrod) && user.password !== user.confirm_password) {
			_errors = _errors ? _errors : {};
			_errors['confirm_password'] = `Hasło Nie pasuje.`;
		}

		setErrors(_errors);
		if (_errors) return;

		setProgressStatus(true);
		removeAllToasts();
		
		let _user = {...user};
		delete _user.confirm_password;
		if(!_user.password) delete _user.password;

		(id ? API.users.update : API.users.create)({user, permissions}, id)
			.then(res => {
				setProgressStatus(false)
				if (res.code === 400) {
					addToast(res.message, toastOptions);
					setErrors({ email: true });
					return;
				}

				addToast(res.message, { ...toastOptions, appearance: 'success' });
				setTimeout(() => {
					history.push(Routes.Users.List);
				}, 300);
			})
			.catch(() => setProgressStatus(false));
	}

	return (
		<>
			<Grid container justifyContent="space-between" >
				<BackToMain breadcrumbList={breadcrumbList} title={`Wróć do listy studentów`} onClick={() => history.push(Routes.Users.List)} />
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<Card className={`${classes.card} ${classes.card_left}`}>
						<Title value={`Dane podstawowe`} />
						<FormInput
							title={'E-mail'}
							name={'email'}
							value={user.email}
							onChange={handleChange}
							error={errors?.email}
						/>
						<Grid container spacing={3}>
							<Grid item xs={12} md={4}></Grid>
							<Grid item xs={12} md={8}>
								<Checkbox
									title={`Aktywny`}
									name={'active'}
									value={user.active}
									onChange={handleChange} />
							</Grid>
						</Grid>

						<Title value={`Zmień hasło`} />
						<FormInput
							title={'Nowe hasło'}
							name={'password'}
							value={user.password}
							onChange={handleChange}
							error={errors?.password}
							type={`password`}
						/>
						<FormInput
							title={'Powtórz nowe hasło'}
							name={'confirm_password'}
							value={user.confirm_password}
							onChange={handleChange}
							error={errors?.confirm_password}
							type={`password`}
						/>
					</Card>
					<Card className={`${classes.card} ${classes.card_left}`}>
						<Title value={`Uprawnienia`} />
						{permissionsList && permissionsList.map((item, i) => (
							<Grid key={i} container spacing={3}>
								<Grid item xs={12} md={4}></Grid>
								<Grid item xs={12} md={8}>
									<Checkbox
										title={item.name}
										name={item.name}
										value={permissions.includes(item.id)}
										onChange={e => handlePermissionChange(e, item.id)} />
								</Grid>
							</Grid>
						))}
					</Card>
				</Grid>
				<Grid item xs={3}>
					<Card className={classes.card}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<SaveButton
									onClick={handleSave}
									saving={progressStatus} />
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>
			<Progress status={progressStatus} />
		</>
	);
};

export default UserForm;
