import React from 'react';
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import {makeStyles} from "@material-ui/styles";
import LocaleButtons from "../Buttons/LocaleButtons";
import Constants from "../../utils/Constants";
import clsx from "clsx";
import ErrorLabel from "./ErrorLabel";

const useStyles = makeStyles(() => ({
    qlClass: {
        '& .ql-container': {
            background: 'white',
            height: '150px',
            overflow: 'auto',
        },
        '& .ql-editor': {
            whiteSpace: 'normal !important',
        },
        '& .ql-tooltip': {
            left: '10px !important',
        },
    },
    error: {
        '& .ql-container': {
            border: '1px solid red',
        },
        '& .ql-toolbar': {
            border: '1px solid red',
        },
    },
}));

const isQuillEmpty = quill => {
    if ((quill.getContents()['ops'] || []).length !== 1)
        return false;
    return quill.getText().trim().length === 0;
};

export default function Html({name, value, translatable, error, onChange}) {
    const classes = useStyles();
    const [locale, setLocale] = React.useState('pl');

    const change = locale => (content, delta, source, editor) => onChange?.({
        content,
        delta,
        source,
        editor,
        target: {
            dataset: {locale},
            value: isQuillEmpty(editor) ? '' : content,
            name,
        },
    });

    return (
        <>
            {!!translatable && <LocaleButtons value={locale} onChange={setLocale} errors={error}/>}
            {!!translatable && Constants.Locales.map(local => (
                <div key={local} hidden={local !== locale}>
                    <ReactQuill
                        onChange={change(local)}
                        value={value?.[local] || ''}
                        className={clsx({
                            [classes.qlClass]: true,
                            [classes.error]: !!error?.[local],
                        })}
                    />
                    <ErrorLabel error={error?.[local]}/>
                </div>
            ))}
            {!translatable && (
                <>
                    <ReactQuill
                        onChange={change()}
                        value={value || ''}
                        className={clsx({
                            [classes.qlClass]: true,
                            [classes.error]: !!error,
                        })}
                    />
                    <ErrorLabel error={error}/>
                </>
            )}
        </>
    );
};
