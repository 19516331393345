import React from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications'
import { TableCell, TableRow, Grid } from '@material-ui/core';
import moment from 'moment';

import API from 'apis/API';
import Routes from 'router/Routes';
import Messages from 'utils/Messages';

import PaginatedTable from 'components/Table/PaginatedTable';
import FillButton from 'components/Buttons/FillButton';
import { EditIconButton, DeleteIconButton } from 'components/Buttons/IconButtons';
import { Visible, VisibleOff } from 'components/Text/Visible';

const News = props => {
    const history = useHistory();
    const { addToast } = useToasts();
    const { title } = props;

    const columns = [
        {
            title: 'ID',
            name: 'id',
        },
        {
            title: 'Tytuł aktualności',
            name: 'title',
        },
        {
            title: 'Autor',
            name: 'author',
        },
        {
            title: 'Data publikacji',
            name: 'published_at',
        },
        {
            title: 'Data wpisu',
            name: 'updated_at',
        },
        {
            title: 'Wpis widoczny',
            name: 'visible',
            filters: [
                { title: 'Wszystkie', value: 2 },
                { title: 'Tylko widoczne', value: 1 },
                { title: 'Tylko ukryte', value: 0 }
            ],
            width: 135
        },
        {
            title: 'Akcje',
            name: 'action',
            width: 100
        },
    ];

    const buttons = [
        {
            component: FillButton,
            title: 'Dodaj aktualności',
            onClick: () => history.push(Routes.News.Create)
        },
    ];

    const handleDelete = id => {
        API.news.delete(id).then(res => {
            let message = res.data.message;
            API.files.delete(id, 'news').then(res => {
				addToast(message, { appearance: 'success', autoDismissTimeout: 3000, autoDismiss: true });
			});
        });
    }

    const renderRow = (item, index) => (
        <TableRow key={index} >
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.title.pl}</TableCell>
            <TableCell>{item?.author?.name}</TableCell>
            <TableCell>{moment(item.published_at).format('DD.MM.YYYY HH:mm')}</TableCell>
            <TableCell>{moment(item.updated_at).format('DD.MM.YYYY HH:mm')}</TableCell>
            <TableCell>{parseInt(item.is_visible) ? <Visible /> : <VisibleOff />}</TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={6}>
                        <EditIconButton onClick={() => history.push(Routes.News.Edit(item.id))} />
                    </Grid>
                    <Grid item xs={6}>
                        <DeleteIconButton onClick={() => handleDelete(item.id)} title={Messages.Delete} />
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );

    return (
        <PaginatedTable
            title={title}
            buttons={buttons}
            endpoint={API.news.index}
            columns={columns}
            renderRow={renderRow}
        />
    );
};

export default News;
