import React from "react";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(() => ({
    error_label: {
        color: 'red',
        fontSize: '0.75em',
        marginTop: '5px',
    },
}));

export default function ErrorLabel({error}) {
    const classes = useStyles();
    return (
        <div className={classes.error_label} style={{display: error ? 'block' : 'none'}}>
            {error}
        </div>
    );
};
