import React, { forwardRef, useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button } from '@material-ui/core';
import API from "apis/API";

const useStyles = makeStyles(theme => ({
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    title: {
        width: '180px',
    },
    button: {
        padding: '16px 24px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: 400,
        fontSize: '0.8750em',
        color: theme.palette.sidebar_color,
        lineHeight: '1em',
        '& .icon': {
            width: '20px',
            height: '20px',
            marginRight: theme.spacing(3),

            '& path': {
                fill: theme.palette.sidebar_color,
            }
        },
        '&:hover': {
            backgroundColor: theme.palette.sidebar_color,
            color: theme.palette.sidebar_hover_color,
            fontWeight: 400,
            borderRadius: '0px',

            '& .icon': {
                color: theme.palette.sidebar_hover_color,
                '& path': {
                    fill: theme.palette.sidebar_hover_color,
                },
            },
        },
    },
    active: {
        fontWeight: 400,
        '& .icon path': {
            fill: theme.palette.sidebar_hover_color,
        },
        backgroundColor: theme.palette.sidebar_color,
        color: theme.palette.sidebar_hover_color,
        borderRadius: '0px',
    },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
        <NavLink {...props} />
    </div>
));

const SidebarNav = props => {
    const { pages, className, ...rest } = props;
    const history = useHistory();
    const classes = useStyles();
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        API.auth.validateToken().then(res => setPermissions(res.data?.user?.permissions ?? []));
    }, []);

    const handleClick = (page) => history.push(page.href);

    return (
        <List {...rest} className={clsx(classes.root, className)}>
            {pages.map(page => (
                permissions && permissions.includes(page.id) &&
                <React.Fragment key={page.href}>
                    <ListItem
                        className={classes.item}
                        disableGutters
                        key={page.title}
                        onClick={() => handleClick(page)}
                    >
                        <Button
                            activeClassName={classes.active}
                            className={classes.button}
                            component={CustomRouterLink}
                            to={page.href}
                        >
                            {page?.icon}
                            <div className={classes.title}>
                                {page.title}
                            </div>
                        </Button>
                    </ListItem>
                </React.Fragment>
            ))}
        </List>
    );
};

export default SidebarNav;
