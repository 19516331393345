import React from 'react';
import { Grid } from '@material-ui/core';
import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons';

export const Visible = props => {
    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={6} >TAK</Grid>
            <Grid item xs={6} container>
                <VisibilityOutlined />
            </Grid>
        </Grid>
    );
};

export const VisibleOff = props => {
    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={6}>NIE</Grid>
            <Grid item xs={6} container>
                <VisibilityOffOutlined />
            </Grid>
        </Grid>
    );
};
