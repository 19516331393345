import React, { useState, useEffect } from 'react';
import {
	Grid, Card
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useToasts } from 'react-toast-notifications'

import Routes from 'router/Routes';
import API from 'apis/API';
import Validator, { Required, validate } from "utils/Validator";
import BackToMain from 'components/Buttons/BackToMain';
import SaveButton from 'components/Buttons/SaveButton';
import Progress from 'components/Main/Progress';
import Title from 'components/Text/Title';
import Html from "components/Form/Html";
import FormInput from 'components/Form/FormInput';
import ThumbnailPicker from 'components/Form/ThumbnailPicker';
import Messages from 'utils/Messages';

const useStyles = makeStyles((theme) => ({
	card: {
		marginBottom: theme.spacing(4)
	},
	card_left: {
		paddingRight: theme.spacing(20)
	},
	label: {
		fontWeight: 500,
		fontFamily: 'roboto',
		fontSize: '0.875em',
		marginBlock: theme.spacing(2)
	},
	subtitle: {
		fontSize: 14,
		fontWeight: 700,
		marginBottom: theme.spacing(1)
	}
}));

const VirtualWalkInfo = props => {
	const { title, history } = props;
	const classes = useStyles();
	const { addToast } = useToasts()
	const breadcrumbList = [
		{
			type: 'route',
			route: Routes.VirtualWalk.List,
			label: 'Lista punktów'
		},
		{ label: 'Informacje o spacerze' }
	]

	const [data, setData] = useState({
		title: { pl: '', en: '' },
		description: { pl: '', en: '' },
		introduction: { pl: '', en: '' },
	});
	const [files, setFiles] = useState({
		image: null
	});
	const [errors, setErrors] = useState(null);
	const [progressStatus, setProgressStatus] = useState(false);
	const Validators = {
		title: new Validator(Required),
		description: new Validator(Required),
		introduction: new Validator(Required),
	};

	useEffect(() => {
		API.virtualWalk.getInfo().then(res => {
			
			if (!res?.data) return;
			setData(res.data);
			setFiles(prev => ({ ...prev, image: res.data?.files?.image?.[0] }));
		});
	}, []);

	const handleChange = e => {
		const target = e.target;
		const locale = target.dataset?.locale;
		const name = target.name;
		const value = target.value;

		setData(prev => {
			prev[name] = locale
				? { ...prev[name], [locale]: value }
				: value;
			return { ...prev }
		})
	}

	const handleFileChange = e => {
		setFiles(prev => ({ ...prev, image: e.target.value }));
	}

	const handleSave = () => {
		let _errors = validate(data, Validators);
		setErrors(_errors);
		if (_errors) return;

		setProgressStatus(true);
		API.virtualWalk.setInfo(data)
			.then(res => {
				API.files.upload(files, 1, 'virtual_walk_information').then(res => {
					setProgressStatus(false);
					addToast(Messages.SuccessResponse, { autoDismissTimeout: 3000, autoDismiss: true, appearance: 'success' });
					setTimeout(() => {
						history.push(Routes.VirtualWalk.List);
					}, 300);
				});
			}).catch(err => setProgressStatus(false));
	}

	return (
		<>
			<Grid container justifyContent="space-between" >
				<BackToMain breadcrumbList={breadcrumbList} title={`Wróć do listy punktów`} onClick={() => history.push(Routes.VirtualWalk.List)} />
			</Grid>
			<Title value={title} />
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<Card className={`${classes.card} ${classes.card_left}`}>
						<Title value={`Dane podstawowe`} />
						<FormInput
							title={'Tytuł'}
							name={'title'}
							value={data.title}
							onChange={handleChange}
							error={errors?.title}
							translatable
						/>

						<Grid item xs={12}>
							<Grid item className={classes.label}>
								<label>Wstęp</label>
							</Grid>
							<Html
								name={'introduction'}
								value={data.introduction}
								onChange={handleChange}
								error={errors?.introduction}
								translatable
							/>
						</Grid>
					</Card>
					<Card className={`${classes.card} ${classes.card_left}`}>
						<Title value={`Opis`} />
						<Grid item xs={12}>
							<Html
								name={'description'}
								value={data.description}
								onChange={handleChange}
								error={errors?.description}
								translatable
							/>
						</Grid>
					</Card>
				</Grid>
				<Grid item xs={3}>
					<Card className={classes.card}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<SaveButton
									onClick={handleSave}
									saving={progressStatus} />
							</Grid>
						</Grid>
					</Card>

					<Card className={classes.card}>
						<Grid className={classes.subtitle}>Obrazek wyróżniający</Grid>
						<ThumbnailPicker value={files.image} name={`image`} onChange={handleFileChange} editLabel="Dodaj obrazek" deleteLabel="Usuń obrazek" />
					</Card>
				</Grid>
			</Grid>
			<Progress status={progressStatus} />
		</>
	);
};

export default VirtualWalkInfo;
