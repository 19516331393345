import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link, Breadcrumbs } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  breadcrumb: {
    color: theme.palette.sidebar_title_color,
    fontFamily: 'roboto',
    display: 'flex',
    alignItems: 'center'
  },
  link: {
    color: theme.palette.blue,
    fontSize: '0.8125em',
    cursor: 'pointer',
  },
  typo: {
    color: theme.palette.sidebar_title_color,
    fontSize: '0.8125em',
  }
}));

const RouteBreadcrumb = props => {
    const {route, label} = props;
    const history = useHistory();

    const classes = useStyles();
    return (
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
            <Link color="inherit" onClick={() => history.push(route)} className={classes.link}>
                {label}
            </Link>
        </Breadcrumbs>
    );
};

export default RouteBreadcrumb;
