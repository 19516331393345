import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Drawer } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import Routes from "../../router/Routes";
import { StudentsIcon, JobSearchIcon, PlaceIcon, TeamIcon, HandshakeIcon, HomeIcon, CalendarIcon, BellIcon } from "../../assets/svg/icons";
import SidebarNav from "./SidebarNav";

const useStyles = makeStyles(theme => ({
    drawer: {
        width: 300,
        borderRight: theme.palette.sidebar_border,
    },
    root: {
        backgroundColor: theme.palette.white,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    logoBlock: {
        textAlign: 'center',
    },
    main_logo: {
        width: '60%',
        marginRight: theme.spacing(4)
    },
}));

const Sidebar = props => {
    const { open, variant, onClose, className, staticContext, ...rest } = props;
    const classes = useStyles();

    const items = [
        {
            id: 1,
            title: 'Lista studentów',
            href: Routes.Students.List,
            icon: <StudentsIcon className={`icon`} />
        },
        {
            id: 2,
            title: 'Aktualności i komunikaty',
            href: Routes.News.List,
            icon: <JobSearchIcon className={`icon`} />
        },
        {
            id: 3,
            title: 'Wydarzenia',
            href: Routes.Events.List,
            icon: <PlaceIcon className={`icon`} />
        },
        {
            id: 4,
            title: 'Wirtualny spacer',
            href: Routes.VirtualWalk.List,
            icon: <TeamIcon className={`icon`} />
        },
        {
            id: 5,
            title: 'Baza szkoleń',
            href: Routes.Trainings.List,
            icon: <TeamIcon className={`icon`} />
        },
        {
            id: 6,
            title: 'Kalendarz',
            href: Routes.Calendar.List,
            icon: <TeamIcon className={`icon`} />
        },
        {
            id: 7,
            title: 'Niezbędnik studenta I roku',
            href: Routes.Questions.List,
            icon: <HandshakeIcon className={`icon`} />
        },
        {
            id: 8,
            title: 'Moje studia',
            href: Routes.Studies.List,
            icon: <HomeIcon className={`icon`} />
        },
        {
            id: 9,
            title: 'Zdrowie',
            href: Routes.Health.Create,
            icon: <HomeIcon className={`icon`} />
        },
        {
            id: 10,
            title: 'Media społecznościowe',
            href: Routes.Social.Create,
            icon: <CalendarIcon className={`icon`} />
        },
        {
            id: 11,
            title: 'Deklaracja dostępności',
            href: Routes.Accessibility.Create,
            icon: <BellIcon className={`icon`} />
        },
        {
            id: 12,
            title: 'Pomoc techniczna',
            href: Routes.Support.Create,
            icon: <HomeIcon className={`icon`} />
        },
        {
            id: 13,
            title: 'Biblioteka - tekst',
            href: Routes.CommonInformation.Create,
            icon: <HomeIcon className={`icon`} />
        },
        {
            id: 14,
            title: 'Użytkownicy',
            href: Routes.Users.List,
            icon: <HomeIcon className={`icon`} />
        },
    ];

    return (
        <Drawer
            anchor="left"
            classes={{ paper: classes.drawer }}
            onClose={onClose}
            open={open}
            variant={variant}
        >
            <div {...rest} className={clsx(classes.root, className)}>
                <div className={classes.logoBlock}>
                    <img src="/images/logo.png" className={classes.main_logo} alt="Main Logo" />
                </div>
                <SidebarNav className={classes.nav} pages={items} />
            </div>
        </Drawer>
    );
};

Sidebar.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired
};

export default Sidebar;
