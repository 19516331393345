import React, { useMemo } from 'react';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableSortLabel,
    Card,
    CircularProgress, Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Pagination from "@material-ui/lab/Pagination";
import API from "../../apis/API";
import { useHistory, useLocation } from "react-router-dom";
import Title from "../Text/Title";
import FillButton from "../Buttons/FillButton";
import SearchInput from "../Form/SearchInput";
import FilterInput from "../Form/FilterInput";
import Dropdown from "../Form/PlainInputs/Dropdown";
import {DeleteIconButton, EditIconButton} from "../Buttons/IconButtons";
import Messages from "../../utils/Messages";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";

const useStyles = makeStyles(theme => ({
    controlBlock: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    controlBlockButtons: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    controlBlockFilters: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    btnCreate: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.btn_gray,
        },
        padding: theme.spacing(0.5, 6, 0.5, 2),
        borderRadius: '0px',
        fontWeight: '300',
        backgroundColor: theme.palette.btn_gray,
        color: theme.palette.black_white,
        [theme.breakpoints.up('xs')]: {
            marginRight: '0px',
            marginBottom: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
            marginRight: '20px',
        },
    },
    button_list: {
        [theme.breakpoints.up('xs')]: {
            display: 'grid',
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    btnExport: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        '&:hover': {
            backgroundColor: theme.palette.pink,
            borderColor: theme.palette.pink,
            color: theme.palette.black_white,
        },
        padding: theme.spacing(0.5, 8),
        borderRadius: '0px',
        fontWeight: '300',
        color: theme.palette.pink,
        borderColor: theme.palette.pink,
        height: '100%',
    },
    divide: {
        backgroundColor: 'lightgray',
        height: '1px',
        width: '100%',
        margin: theme.spacing(2.5, 0),
    },
    rowsBlock: {
        color: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'roboto',
        fontSize: '0.9375em',
    },
    filter: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    table: {
        margin: theme.spacing(2.5, 0),
        border: theme.palette.card_border,
    },
    pagination: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1),
    },
    pagination_class: {
        '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: theme.palette.pagination_background,
            color: theme.palette.pagination_color,
        },
    },
}));

const Item = ({item, itemData, i, moveItem, renderRow}) => {
    const ref = React.useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: 'item',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current)
                return;

            const dragIndex = item.index;
            const hoverIndex = i;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex)
                return;

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
                return;
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
                return;
            moveItem(dragIndex, hoverIndex, itemData);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'item',
        item: () => {
            return { id: item.id, index: i };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <TableRow style={{opacity: isDragging ? 0.5 : 1}} ref={ref} data-handler-id={handlerId}>
            {renderRow(item, i)}
        </TableRow>
    );
};

const PaginatedTable = props => {
    const { title, buttons, filters, columns, endpoint, renderRow, sortedByOrder, draggableItems, onOrderUpdate} = props;
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const page = parseInt(urlParams.get('page')) || 1;
    const search = urlParams.get('search') || '';
    const direction = urlParams.get('direction') || 'desc';
    const selectedFilters = useMemo(() => [...urlParams.entries()].filter(
        ([key,]) => filters?.some(({ name }) => key === name)
    ), [urlParams]);
    const column = parseInt(urlParams.get('column')) || 0;
    const visible = isNaN(parseInt(urlParams.get('visible'))) ? 2 : parseInt(urlParams.get('visible'));

    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        if (!endpoint)
            return;

        setData(null);

        const params = {
            page,
            direction,
            column: columns[column].name,
            search,
            visible,
            ...Object.fromEntries(selectedFilters)
        };

        let promise;

        switch (typeof endpoint) {
            case 'string':
                promise = API.get(endpoint, { params });
                break;
            case 'function':
                promise = endpoint(params);
                break;
            default:
                throw new TypeError('Endpoint of type ' + typeof endpoint + ' is not supported');
        }

        promise.then(response => setData(sortedByOrder ? {...response.data, data: response.data?.data?.sort((a, b) => (a.order < b.order) ? -1 : 1).map((item, index) => ({...item, order: item.order || index + 1}))} : response.data)).catch(error => { setData(false); console.error(error) });
    }, [endpoint, columns, page, search, direction, column, selectedFilters, visible]);

    React.useEffect(() => {
        if (!!draggableItems && typeof onOrderUpdate === 'function' && !!data?.data?.length)
            onOrderUpdate(data?.data);
    },[data]);

    const moveItem = React.useCallback((dragIndex, hoverIndex, itemData) => {
        const itemToRemove = data.data[hoverIndex];
        const itemToPlace = data.data[dragIndex];
        const newData = [...data.data];
        newData[hoverIndex] = itemToPlace;
        newData[dragIndex] = itemToRemove;
        setData({...data, data: newData.map((item, index) => ({...item, order: index + 1}))});
    }, [data]);

    const pushHistory = () => history.push({
        pathname: location.pathname,
        search: new URLSearchParams([...urlParams.entries()].filter(([, value]) => value !== '')).toString(),
    });

    const handleSearch = value => {
        urlParams.set('search', value);
        urlParams.set('page', '1');
        pushHistory();
    };
    const handleFilter = (name, onChange) => value => {
        if (onChange) onChange(value);
        if(typeof value === 'object') value = value.target.value;
        urlParams.set(name, value);
        pushHistory();
    };
    const onColumnPress = index => () => {
        if (index === column)
            urlParams.set('direction', direction === 'asc' ? 'desc' : 'asc');
        else
            urlParams.set('column', index);
        pushHistory();
    };
    const changePage = (e, page) => {
        urlParams.set('page', page);
        pushHistory();
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <Title value={title} />
            <div className={classes.controlBlock}>
                <div className={classes.controlBlockButtons}>
                    {buttons?.map((button, index) => (
                        <FillButton key={index} title={button.title} onClick={button.onClick} />
                    ))}
                </div>
                <div className={classes.controlBlockFilters}>
                    {filters?.map((filter, index) => (
                        <FilterInput key={index} value={Object.fromEntries(selectedFilters)[filter.name]} id={index} label={filter.label} options={filter.options} onFilterChange={handleFilter(filter.name, filter.onChange)} />
                    ))}
                    <SearchInput handleSearch={handleSearch} />
                </div>
            </div>
            <Card className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((info, index) => (
                                <TableCell key={info.name} width={info?.width}>
                                    {info.filters
                                        ? <>
                                            <label>{info.title}</label>
                                            <Dropdown
                                                name={info.name}
                                                value={urlParams.get(info.name) ?? info.filters[0].value}
                                                options={info.filters}
                                                onChange={handleFilter(info.name)}
                                                valueField={`value`}
                                            />
                                        </>
                                        : <TableSortLabel
                                            active={column === index}
                                            direction={direction}
                                            onClick={onColumnPress(index)}
                                        >
                                            <label htmlFor={info.name}>
                                                {info.title}
                                            </label>
                                        </TableSortLabel>
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data === null && (
                            <TableRow>
                                <TableCell colSpan={columns.length} align={'center'}>
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        )}
                        {!!data?.data?.length && data.data.map(draggableItems ? (item, index) => <Item key={index} item={item} i={index} renderRow={renderRow} itemData={item} moveItem={moveItem} onOrderUpdate={onOrderUpdate}/> : renderRow)}
                    </TableBody>
                </Table>
                <div className={classes.pagination}>
                    <Pagination
                        className={classes.pagination_class}
                        count={(data?.per_page && data?.total) ? Math.ceil(data.total / data.per_page) : null}
                        onChange={changePage}
                        page={page}
                        showFirstButton
                        showLastButton
                    />
                </div>
            </Card>
        </DndProvider>
    );
};

export default PaginatedTable
