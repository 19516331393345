import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ToastProvider } from "react-toast-notifications";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/pl";

import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/scss/index.scss";

import theme from "./theme";
import AppContainer from "./AppContainer";
import { Interceptors } from "./apis/Interceptors";
import RoutesRouter from "./router/RoutesRouter";

export const SiteInfoContext = React.createContext(null);

Interceptors.register();
moment.locale('pl');

const App = () => {
    const [isContrast, setIsContrast] = useState(false);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'pl'}>
            <ToastProvider>
                <SiteInfoContext.Provider value={{ toggleContrast: () => setIsContrast(!isContrast) }}>
                    <ThemeProvider theme={theme(isContrast)}>
                        <BrowserRouter>
                            <AppContainer>
                                <RoutesRouter />
                            </AppContainer>
                        </BrowserRouter>
                    </ThemeProvider>
                </SiteInfoContext.Provider>
            </ToastProvider>
        </MuiPickersUtilsProvider>
    );
}

export default App;