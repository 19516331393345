import React from "react";
import { FormControl, FormControlLabel, RadioGroup, Radio as MUIRadio } from "@material-ui/core";

export default function Radio({ title, name, value, onChange, list, className, ...rest }) {
    return (
        <FormControl component="fieldset">
            <RadioGroup aria-label={name} name={name} value={value} onChange={onChange} row>
                {list.map(item => (
                    <FormControlLabel key={item.name} value={item.name} control={<MUIRadio />} label={item.label} className={className} />
                ))}
            </RadioGroup>
        </FormControl>
    );
};
