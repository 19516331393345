const MuiTableHead = {
	root: {
		backgroundColor: '#000000',
		'& .MuiTableCell-root': {
			padding: '8px 16px',
		},
		'& .MuiTableCell-head': {
			fontWeight: '400',
			fontSize: '0.7em'
		}
	}
};

export default MuiTableHead;
