import React, { useState, useEffect } from 'react';
import {
	Grid, Card
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useToasts } from 'react-toast-notifications'

import Routes from 'router/Routes';
import API from 'apis/API';
import Validator, { Required, FileSizeLimit, validate } from 'utils/Validator';

import BackToMain from 'components/Buttons/BackToMain';
import SaveButton from 'components/Buttons/SaveButton';
import DeleteButton from 'components/Buttons/DeleteButton';
import Progress from 'components/Main/Progress';
import Title from 'components/Text/Title';
import Html from 'components/Form/Html';
import Dropdown from 'components/Form/PlainInputs/Dropdown';
import FilesPicker from 'components/Form/FilesPicker';
import Messages from 'utils/Messages';

const useStyles = makeStyles((theme) => ({
	card: {
		marginBottom: theme.spacing(4)
	},
	cardLeft: {
		paddingRight: theme.spacing(20)
	},
	subtitle: {
		fontSize: 14,
		fontWeight: 700,
	},
}));

const StudiesForm = props => {
	const { title, history } = props;
	const id = props.match.params.id;
	const classes = useStyles();
	const { addToast } = useToasts();
	const [breadcrumbList, setBreadcrumbList] = useState([
		{
			type: 'route',
			route: Routes.Studies.List,
			label: 'Lista kierunków'
		},
		{ label: '' }
	]);
	const [groupAssigments, setGroupAssigments] = useState(null);
	const [data, setData] = useState({
		group_assigment_id: '',
		introduction: { pl: '', en: '' },
		description: { pl: '', en: '' },
	});
	const [files, setFiles] = useState({
		main: null,
	});
	const [errors, setErrors] = useState(null);
	const [progressStatus, setProgressStatus] = useState(false);
	const Validators = {
		introduction: new Validator(Required),
		description: new Validator(Required),
	};
	const FileValidators = {
		main: new Validator(FileSizeLimit),
	};

	useEffect(() => {
		API.studies.getGroupAssigments().then(res => {
			let _data = res.data;
			setGroupAssigments(_data);
			if (_data?.[0]?.programe) {
				setBreadcrumbList(prev => {
					prev[1].label = _data?.[0]?.programe || '';
					return [...prev];
				});
			}
		});

		if (!id) return;
		API.studies.show(id).then(res => {
			if (!res?.data) return history.push(Routes.Studies.Create);
			setData(res.data);
			setFiles(res.data?.files);

			setBreadcrumbList(prev => {
				prev[1].label = res?.data?.group_assigment?.programe || '';
				return [...prev];
			});
		}).catch(err => history.goBack());
	}, []);

	const handleChange = e => {
		const target = e.target;
		const locale = target.dataset?.locale;
		const name = target.name;
		const value = target.value;

		setData(prev => {
			prev[name] = locale
				? { ...prev[name], [locale]: value }
				: value;
			return { ...prev }
		});

		if (name === 'group_assigment_id') {
			setBreadcrumbList(prev => {
				let groupAssigment = groupAssigments.find(item => item.slug === value);
				prev[1].label = groupAssigment?.programe || '';
				return [...prev];
			});
		}
	}

	const handleFileChange = e => {
		const target = e.target;
		const name = target.name;
		const value = target.value;
		setFiles(prev => {
			prev[name] = value;
			return { ...prev }
		});
	}

	const handleSave = () => {
		let _errors = validate(data, Validators);
		let fileErrors = validate(files, FileValidators);
		setErrors({ ..._errors, file: fileErrors });
		if (_errors || fileErrors) return;
		setProgressStatus(true);

		(id ? API.studies.update : API.studies.create)(data, id)
			.then(res => {
				const newId = res?.data?.id;
				if (!newId) {
					setProgressStatus(false);
					addToast(res.message, { autoDismissTimeout: 3000, autoDismiss: true, appearance: 'error' });
				}

				API.files.upload(files, newId, 'studies').then(res => {
					setProgressStatus(false);
					addToast(Messages.SuccessResponse, { autoDismissTimeout: 3000, autoDismiss: true, appearance: 'success' });
					setTimeout(() => {
						history.push(Routes.Studies.List);
					}, 300);
				});
			}).catch(err => setProgressStatus(false));
	}

	const handleDelete = () => {
		setProgressStatus(true);
		API.studies.delete(id).then(res => {
			let message = res.data.message;
			API.files.delete(id, 'studies').then(res => {
				setProgressStatus(false);
				addToast(message, { appearance: 'success', autoDismissTimeout: 3000, autoDismiss: true });
				setTimeout(() => {
					history.push(Routes.Studies.List);
				}, 300);
			});
		});
	}

	return (
		<>
			<Grid container justifyContent="space-between" >
				<BackToMain breadcrumbList={breadcrumbList} title={`Wróć do listy kierunków`} onClick={() => history.push(Routes.Studies.List)} />
			</Grid>
			<Title value={title} />
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<Card className={`${classes.card} ${classes.cardLeft}`}>
						<Title value={`Dane podstawowe`} />
						<Grid container spacing={3}>
							<Grid item xs={12} md={4} container alignItems="center"><label className={classes.subtitle}>Kierunek</label></Grid>
							<Grid item xs={12} md={8}>
								{groupAssigments &&
									<Dropdown
										name={'group_assigment_id'}
										valueField="slug"
										titleField="programe"
                                        customPlaceholder={data?.programe}
										value={/*!data?.programe?.length &&*/ data.group_assigment_id}
										options={groupAssigments}
										onChange={handleChange}
									/>
								}
							</Grid>
						</Grid>
					</Card>
					<Card className={`${classes.card} ${classes.cardLeft}`}>
						<Title value={`Wstęp`} />
						<Grid item xs={12}>
							<Html
								name={'introduction'}
								value={data.introduction}
								onChange={handleChange}
								error={errors?.introduction}
								translatable
							/>
						</Grid>
					</Card>
					<Card className={`${classes.card} ${classes.cardLeft}`}>
						<Title value={`Opis`} />
						<Grid item xs={12}>
							<Html
								name={'description'}
								value={data.description}
								onChange={handleChange}
								error={errors?.description}
								translatable
							/>
						</Grid>
					</Card>
					<Card className={`${classes.card} ${classes.cardLeft}`}>
						<Title value={`Załączniki`} />
						<FilesPicker
							name={'main'}
							value={files.main}
							onChange={handleFileChange}
							error={errors?.file?.main}
							multiple
						/>
					</Card>
				</Grid>
				<Grid item xs={3}>
					<Card className={classes.card}>
						<Grid container spacing={2}>
							<Grid item xs={id ? 6 : 12}>
								<SaveButton
									onClick={handleSave}
									saving={progressStatus} />
							</Grid>
							{id &&
								<Grid item xs={6}>
									<DeleteButton
										title={Messages.Delete}
										handleDelete={handleDelete} />
								</Grid>
							}
						</Grid>
					</Card>
				</Grid>
			</Grid>
			<Progress status={progressStatus} />
		</>
	);
};

export default StudiesForm;
