import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Breadcrumbs } from '@material-ui/core';
import TextBreadcrumb from './TextBreadcrumb';
import LinkBreadcrumb from './LinkBreadcrumb';
import RouteBreadcrumb from './RouteBreadcrumb';

const useStyles = makeStyles(theme => ({
  breadcrumb: {
    color: theme.palette.sidebar_title_color,
    fontFamily: 'roboto',
    display: 'flex',
    alignItems: 'center'
  },
  link: {
    color: theme.palette.blue,
    fontSize: '0.8125em',
  },
  typo: {
    color: theme.palette.sidebar_title_color,
    fontSize: '0.8125em',
  }
}));

const Breadcrumb = props => {
    const { list } = props;

    const classes = useStyles();
    return (
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
            {
                list && list.map((item, index) => {
                    return ({
                        'text':  () => <TextBreadcrumb  key={index} {...item} />,
                        'link':  () => <LinkBreadcrumb  key={index} {...item} />,
                        'route': () => <RouteBreadcrumb key={index} {...item} />,
                    })[item.type ?? (item.active ? 'link' : 'text')](item);
                })
            }
        </Breadcrumbs>
    );
};

export default Breadcrumb;
