import React from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { TableCell, TableRow, Grid } from '@material-ui/core';

import API from 'apis/API';
import Routes from 'router/Routes';
import Messages from 'utils/Messages';
import PaginatedTable from 'components/Table/PaginatedTable';
import FillButton from 'components/Buttons/FillButton';
import { EditIconButton, DeleteIconButton } from 'components/Buttons/IconButtons';

const Questions = props => {
    const history = useHistory();
    const { addToast } = useToasts();
    const { title } = props;

    const columns = [
        {
            title: 'ID',
            name: 'id',
            width: 200
        },
        {
            title: 'Pytanie',
            name: 'title',
        },
        {
            title: 'Akcje',
            name: 'action',
            width: 100
        },
    ];

    const buttons = [
        {
            component: FillButton,
            title: 'Dodaj pytanie',
            onClick: () => history.push(Routes.Questions.Create)
        },
        {
            component: FillButton,
            title: 'Wstęp',
            onClick: () => history.push(Routes.Questions.Info)
        },
    ];

    const handleDelete = id => {
        API.questions.delete(id).then(res => {
            let message = res.data.message;
            API.files.delete(id, 'questions').then(res => {
                addToast(message, { appearance: 'success', autoDismissTimeout: 3000, autoDismiss: true });
            });
        });
    };

    const renderRow = (item, index) => (
        <React.Fragment key={index} >
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.title.pl}</TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={6}>
                        <EditIconButton onClick={() => history.push(Routes.Questions.Edit(item.id))} />
                    </Grid>
                    <Grid item xs={6}>
                        <DeleteIconButton onClick={() => handleDelete(item.id)} title={Messages.Delete} />
                    </Grid>
                </Grid>
            </TableCell>
        </React.Fragment>
    );

    return (
        <PaginatedTable
            title={title}
            buttons={buttons}
            endpoint={API.questions.index}
            columns={columns}
            renderRow={renderRow}
            onOrderUpdate={API.questions.reorder}
            sortedByOrder
            draggableItems
        />
    );
};

export default Questions;
