import React from 'react';
import { useHistory } from 'react-router-dom';
import { TableCell, TableRow, Grid } from '@material-ui/core';
import { useToasts } from 'react-toast-notifications'

import API from 'apis/API';
import Routes from 'router/Routes';
import Messages from 'utils/Messages';

import PaginatedTable from 'components/Table/PaginatedTable';
import FillButton from 'components/Buttons/FillButton';
import { EditIconButton, DeleteIconButton } from 'components/Buttons/IconButtons';

const VirtualWalk = props => {
    const history = useHistory();
	const { addToast } = useToasts()

    const { title } = props;

    const columns = [
        {
            title: 'ID',
            name: 'id',
        },
        {
            title: 'Nazwa punktu',
            name: 'title',
        },
        {
            title: 'Akcje',
            name: 'action',
            width: 100
        },
    ];

    const buttons = [
        {
            component: FillButton,
            title: 'Dodaj punkt',
            onClick: () => history.push(Routes.VirtualWalk.Create)
        },
        {
            component: FillButton,
            title: 'Informacje o spacerze',
            onClick: () => history.push(Routes.VirtualWalk.Info)
        },
        {
            component: FillButton,
            title: 'Ustawienia mapy',
            onClick: () => history.push(Routes.VirtualWalk.MapSettings)
        },
    ];

    const handleDelete = id => {
        API.virtualWalk.delete(id).then(res => {
            let message = res.data.message;
            API.files.delete(id, 'virtual_walk').then(res => {
				addToast(message, { appearance: 'success', autoDismissTimeout: 3000, autoDismiss: true });
			});
        });
    }

    const renderRow = (item, index) => (
        <TableRow key={index} >
            <TableCell>{item.id}</TableCell>
            <TableCell>{item?.title?.pl}</TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={6}>
                        <EditIconButton onClick={() => history.push(Routes.VirtualWalk.Edit(item.id))} />
                    </Grid>
                    <Grid item xs={6}>
                    <DeleteIconButton onClick={() => handleDelete(item.id)} title={Messages.Delete} />
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );

    return (
        <PaginatedTable
            title={title}
            buttons={buttons}
            endpoint={API.virtualWalk.index}
            columns={columns}
            renderRow={renderRow}
        />
    );
};

export default VirtualWalk;
