import React, { useState } from 'react';
import clsx from 'clsx';
import { AppBar, Button, IconButton, Menu, MenuItem } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from "react-router-dom";
import Storage from "../../utils/Storage";
import { makeStyles } from "@material-ui/styles";
import Routes from 'router/Routes';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.15), 0px 4px 5px 0px rgba(0,0,0,0.04), 0px 1px 10px 0px rgba(0,0,0,0.02)',
        // left: '249',
        width: 'calc(100% - 299px)',
        height: theme.spacing(7),
        backgroundColor: theme.palette.topbar_background,
        display: 'flex',
        zIndex: '101',
        borderBottom: theme.palette.card_border,
    },
    flexGrow: {
        flexGrow: 1,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'roboto',
        fontSize: '10px',
        color: theme.palette.topbar_color,
        margin: theme.spacing(0, 0, 0, 2),
        cursor: 'pointer',
    },
    signOutButton: {
        marginLeft: theme.spacing(1),
    },
    toolbar: {
        display: 'flex',
        width: '100%',
        padding: '0px 10px',
        justifyContent: 'space-between',
    },
    close_drawer_icon: {
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.topbar_color,
        '& .MuiSvgIcon-root': {
            fontSize: '1.3em',
        },
    },
    avatar: {},
    titlebar: {
        display: 'flex',
        color: theme.palette.topbar_color,
    },
    avataricon: {
        fontSize: '1.3em',
        color: theme.palette.topbar_color,
    },
    rightControllerArea: {
        display: 'flex',
    },
    controllerArea: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    helper: {
        color: theme.palette.topbar_color,
        fontSize: '8px',
        margin: theme.spacing(0, 2.5),
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    vertical_separator: {
        height: '100%',
        width: '1px',
        backgroundColor: theme.palette.topbar_color,
    },
}));

const Topbar = props => {
    const { className, title, onSidebarOpen, onSidebarClose, openSidebar, ...rest } = props;
    const history = useHistory();
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [avatarOpen, setAvatarOpen] = useState(Boolean(anchorEl));

    const onMaxTopbar = () => {
        if (openSidebar === false)
            onSidebarOpen();
        else
            onSidebarClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAvatarOpen(false);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setAvatarOpen(true);
    };

    const handleLogout = () => {
        handleClose();
        Storage.remove('token');
        history.push('/login');
    };

    const handleEdit = () => {
        handleClose();
        history.push(Routes.Profile.Edit);
    }

    return (
        <AppBar {...rest} className={clsx(classes.root, className)}>
            <div className={classes.toolbar}>
                <div className={classes.titlebar}>
                    <Button
                        className={classes.close_drawer_icon} onClick={onMaxTopbar}
                        aria-label={!openSidebar ? 'Rozwiń menu' : 'Zwiń menu'}
                        title={!openSidebar ? 'Rozwiń menu' : 'Zwiń menu'}
                    >
                        <MenuIcon />
                    </Button>
                </div>
                <div className={classes.rightControllerArea}>
                    <div className={classes.title} onClick={handleMenu}>
                        {title}
                    </div>
                    <div className={classes.avatar}>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                        >
                            <AccountCircle className={classes.avataricon} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={avatarOpen}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleEdit}>Edytuj profil</MenuItem>
                            <MenuItem onClick={handleLogout}>Wyloguj</MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
        </AppBar>
    );
};

export default Topbar;
