import React from "react";
import { KeyboardTimePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { extractError } from "../../utils/extractError";

const useStyles = makeStyles(theme => ({
    // todo - time picker styles
    error_label: {
        color: 'red',
        fontSize: '0.75em',
        marginTop: '5px',
        fontFamily: 'roboto',
    },
    column: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

export default function TimeInput({ name, value, onChange, translatable, error, className }) {
    const classes = useStyles();
    const err = extractError(error, translatable, 'pl');

    const change = momentObject => {
        if (!momentObject)
            return;

        // do not override previously set date, month and year
        const valueMoment = moment(value);
        if (valueMoment.isValid()) {
            momentObject.date(valueMoment.date());
            momentObject.month(valueMoment.month());
            momentObject.year(valueMoment.year());
        }

        onChange?.({
            target: {
                name,
                value: momentObject.isValid() ? momentObject.toISOString() : valueMoment.format('YYYY-MM-DD HH:mm'),
            },
        });
    };

    return (
        <div className={classes.column}>
            <KeyboardTimePicker
                invalidDateMessage={null}
                ampm={false}
                value={value}
                inputValue={moment(value).format('HH:mm')}
                format={'HH:mm'}
                onChange={change}
                error={!!error}
                variant={'inline'}
                className={className}
                keyboardIcon={<AccessTimeIcon />}
            />
            <div className={classes.error_label} style={{ display: err ? 'block' : 'none' }}>
                {err}
            </div>
        </div>
    );
};
