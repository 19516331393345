import React, { useState } from 'react';

import LocaleButtons from 'components/Buttons/LocaleButtons';
import FormInput from 'components/Form/FormInput';

const LinkTitle = ({ name = 'title', value, onChange, error, ...rest }) => {
  const [locale, setLocale] = useState('pl');

  const handleChange = (e) => {
    onChange({ target: { name, value: { ...value, [locale]: e.target.value } } })
  }

  return (
    <div style={{display: 'flex'}}>
      <FormInput
        value={value?.[locale]}
        onChange={handleChange}
        error={error?.[locale]}
        {...rest}
      />
      <LocaleButtons iconsOnly value={locale} onChange={setLocale} errors={error} />
    </div>
  );
};

export default LinkTitle;