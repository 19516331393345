import React from "react";
import {FormControlLabel, Checkbox as MUICheckbox} from "@material-ui/core";

export default function Checkbox({title, value, onChange, ...rest}) {
    return (
        <FormControlLabel
            control={(
                <MUICheckbox
                    checked={!!value}
                    onChange={onChange}
                    {...rest}
                />
            )}
            label={title}
        />
    );
};
