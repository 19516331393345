import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    filled_button: {
        '& .MuiButton-label': {
            textTransform: 'none',
            fontSize: '0.8750em',
        },
        padding: theme.spacing(0.5, 2),
        borderRadius: '0px',
        fontWeight: '300',
    },
}));

const OutlineButton = props => {
    const { title, onClick } = props;
    const classes = useStyles();

    return (
        <Button variant="contained" className={classes.filled_button} onClick={onClick}>
            {title}
        </Button>
    );
};

export default OutlineButton;