const MuiTableRow = {
  root: {
    '&$selected': {
			backgroundColor: 'red'
    },
    '&$hover': {
      '&:hover': {
        backgroundColor: 'red'
      }
    },
		'&:nth-of-type(even)': {
      backgroundColor: '#fafafa',
    },
  }
};

export default MuiTableRow;