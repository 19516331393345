import React, { useState, useEffect } from 'react';
import {Grid, Card} from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import { useToasts } from 'react-toast-notifications'
import Routes from "../../router/Routes";
import API from "../../apis/API";
import Validator, { Required, Email, SiteUrl, Latitude, Longitude, PhoneNumber, FileSizeLimit, validate } from 'utils/Validator';
import BackToMain from '../../components/Buttons/BackToMain';
import FormInput from '../../components/Form/FormInput';
import Title from '../../components/Text/Title';
import Html from "../../components/Form/Html";
import SaveButton from 'components/Buttons/SaveButton';
import Progress from 'components/Main/Progress';
import FilesPicker from 'components/Form/FilesPicker';
import Messages from 'utils/Messages';

const useStyles = makeStyles((theme) => ({
	card: {
		marginBottom: theme.spacing(4)
	},
	card_left: {
		paddingRight: theme.spacing(20)
	},
	clinicInput: {
		marginBlock: theme.spacing(0.2)
	},
	label: {
		fontWeight: 500,
		fontFamily: 'roboto',
		fontSize: '0.875em',
		marginBlock: theme.spacing(2)
	},
}));

const HealthForm = props => {
	const { title, history } = props;
	const classes = useStyles();
	const { addToast } = useToasts()
	const breadcrumbList = [
		{ label: 'Zdrowie' }
	];
	const [data, setData] = useState({
		id: 0,
		title: { pl: '', en: '' },
		introduction: { pl: '', en: '' },
		description: { pl: '', en: '' },
	});
	const clinicList = [{ name: 'medicine', title: 'Przychodnia medycyny pracy' }, { name: 'psychological', title: 'Poradnia psychologiczna' }];
	const [clinic, setClinic] = useState({
		medicine: {
			id: 0,
			description: { pl: '', en: '' },
			email: '',
			telephone: '',
			site_url: '',
			address: '',
			latitude: '',
			longitude: '',
		},
		psychological: {
			id: 0,
			description: { pl: '', en: '' },
			email: '',
			telephone: '',
			site_url: '',
			address: '',
			latitude: '',
			longitude: '',
		}
	});
	const [errors, setErrors] = useState(null);
	const [clinicErrors, setClinicErrors] = useState(null);
	const [saving, setSaving] = useState(false);
	const [files, setFiles] = useState({
		main: null,
		medicine: null,
		psychological: null
	});
	const Validators = {
		title: new Validator(Required),
		introduction: new Validator(Required),
		description: new Validator(Required),
	};
	const clinicValidators = {
		description: new Validator(Required),
		email: new Validator(Email),
		telephone: new Validator(PhoneNumber),
		site_url: new Validator(SiteUrl),
		address: new Validator(Required),
		latitude: new Validator(Required, Latitude),
		longitude: new Validator(Required, Longitude),
	}
	const FileValidators = {
		main: new Validator(FileSizeLimit),
		medicine: new Validator(FileSizeLimit),
		psychological: new Validator(FileSizeLimit),
	};

	useEffect(() => {
		API.health.show().then(res => {
			if (!res?.data) return;
			const _data = res.data;

			let medicine = _data.clinic.medicine[0];
			let psychological = _data.clinic.psychological[0];

			setFiles(_data.files);

			delete _data.files;
			delete _data.clinic;

			setClinic({ medicine, psychological });
			setData(_data)
		})
	}, []);

	const handleChange = e => {
		const target = e.target;
		const locale = target.dataset?.locale;
		const name = target.name;
		const value = target.value;

		setData(prev => {
			prev[name] = locale
				? { ...prev[name], [locale]: value }
				: value;
			return { ...prev }
		})
	}

	const handleFileChange = e => {
		const target = e.target;
		const name = target.name;
		const value = target.value;
		setFiles(prev => {
			prev[name] = value;
			return { ...prev }
		});
	}

	const handleClinicChange = (e, type) => {
		const target = e.target;
		const locale = target.dataset?.locale;
		const name = target.name;
		const value = target.value;

		setClinic(prev => {
			prev[type][name] = locale
				? { ...prev[type][name], [locale]: value }
				: value;
			return { ...prev }
		})
	}

	const handleSave = () => {
		let medicineErrors = validate(clinic.medicine, clinicValidators);
		let psychologicalErrors = validate(clinic.psychological, clinicValidators);
		setClinicErrors({ medicine: medicineErrors, psychological: psychologicalErrors });

		let _errors = validate(data, Validators);
		let fileErrors = validate(files, FileValidators);
		setErrors({ ..._errors, file: fileErrors });
		if (_errors || medicineErrors || psychologicalErrors || fileErrors) return;

		setSaving(true);
		API.health.update({ ...data, clinic })
			.then(res => {
				const id = res?.data?.id;
				if (!id) {
					setSaving(false);
					addToast(res.message, { autoDismissTimeout: 3000, autoDismiss: true, appearance: 'error' });
				}

				API.files.upload(files, id, 'health').then(res => {
					setSaving(false);
					addToast(Messages.SuccessResponse, { autoDismissTimeout: 3000, autoDismiss: true, appearance: 'success' });
				});
			}).catch(err => setSaving(false));
	}

	return (
		<>
			<Grid container justifyContent="space-between" >
				<BackToMain breadcrumbList={breadcrumbList} title={`Badania indywidualne - opis`} onClick={() => history.push(Routes.Health.Info)} />
			</Grid>
			<Title value={title} />
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<Card className={`${classes.card} ${classes.card_left}`}>
						<Title value={`Informacje o badaniach - Dane podstawowe`} />
						<FormInput
							title={'Tytuł'}
							name={'title'}
							value={data.title}
							onChange={handleChange}
							error={errors?.title}
							translatable
						/>
						<Grid className={classes.label}>
							<label>Wstęp</label>
						</Grid>
						<Grid item xs={12}>
							<Html
								name={'introduction'}
								value={data.introduction}
								onChange={handleChange}
								error={errors?.introduction}
								translatable
							/>
						</Grid>
					</Card>
					<Card className={`${classes.card} ${classes.card_left}`}>
						<Title value={`Opis`} />

						<Grid item xs={12}>
							<Html
								name={'description'}
								value={data.description}
								onChange={handleChange}
								error={errors?.description}
								translatable
							/>
						</Grid>
					</Card>
					<Card className={`${classes.card} ${classes.card_left}`}>
						<Title value={`Załączniki`} />
						<FilesPicker
							name={'main'}
							value={files.main}
							onChange={handleFileChange}
							error={errors?.file?.main}
							multiple
						/>
					</Card>
					{clinicList.map((item, i) => (
						<Card key={i} className={`${classes.card} ${classes.card_left}`}>
							<Title value={item.title} />
							<Grid item xs={12}>
								<Html
									name={'description'}
									value={clinic[item.name].description}
									onChange={e => handleClinicChange(e, item.name)}
									error={clinicErrors?.[item.name]?.description}
									translatable
								/>
								<FormInput
									title={'E-mail'}
									name={'email'}
									value={clinic[item.name].email}
									onChange={e => handleClinicChange(e, item.name)}
									error={clinicErrors?.[item.name]?.email}
									className={classes.clinicInput}
								/>
								<FormInput
									title={'Telefon'}
									name={'telephone'}
									value={clinic[item.name].telephone}
									onChange={e => handleClinicChange(e, item.name)}
									error={clinicErrors?.[item.name]?.telephone}
									className={classes.clinicInput}
								/>
								<FormInput
									title={'Strona www'}
									name={'site_url'}
									value={clinic[item.name].site_url}
									onChange={e => handleClinicChange(e, item.name)}
									error={clinicErrors?.[item.name]?.site_url}
									className={classes.clinicInput}
								/>
								<FormInput
									title={'Adres (ulica)'}
									name={'address'}
									value={clinic[item.name].address}
									onChange={e => handleClinicChange(e, item.name)}
									error={clinicErrors?.[item.name]?.address}
									className={classes.clinicInput}
								/>
								<FormInput
									title={'Szerokość geograficzna'}
									name={'latitude'}
									value={clinic[item.name].latitude}
									onChange={e => handleClinicChange(e, item.name)}
									error={clinicErrors?.[item.name]?.latitude}
									className={classes.clinicInput}
								/>
								<FormInput
									title={'Wysokość geograficzna'}
									name={'longitude'}
									value={clinic[item.name].longitude}
									onChange={e => handleClinicChange(e, item.name)}
									error={clinicErrors?.[item.name]?.longitude}
									className={classes.clinicInput}
								/>
								<FilesPicker
									name={item.name}
									value={files?.[item.name]}
									onChange={handleFileChange}
									error={errors?.file?.[item.name]}
									multiple
									xs={4}
									labelClassName={classes.label}
								/>
							</Grid>
						</Card>
					))}
				</Grid>
				<Grid item xs={3}>
					<Card className={classes.card}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<SaveButton
									onClick={handleSave}
									saving={saving} />
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>
			<Progress status={saving} />
		</>
	);
};

export default HealthForm;
