import React, { useState, useEffect } from 'react';
import { Grid, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useToasts } from 'react-toast-notifications';
import clsx from 'clsx';

import Routes from 'router/Routes';
import API from 'apis/API';
import Validator, { Required, FileSizeLimit, validate } from 'utils/Validator';
import Messages from 'utils/Messages';

import BackToMain from 'components/Buttons/BackToMain';
import SaveButton from 'components/Buttons/SaveButton';
import Progress from 'components/Main/Progress';
import Title from 'components/Text/Title';
import Html from 'components/Form/Html';
import FormInput from 'components/Form/FormInput';
import ThumbnailPicker from 'components/Form/ThumbnailPicker';
import FilesPicker from 'components/Form/FilesPicker';

const useStyles = makeStyles((theme) => ({
	card: {
		marginBottom: theme.spacing(4)
	},
	cardLeft: {
		paddingRight: theme.spacing(20)
	},
	label: {
		fontWeight: 500,
		fontFamily: 'roboto',
		fontSize: '0.875em',
		marginBlock: theme.spacing(2)
	},
}));

const StudiesInfo = props => {
	const { title, history } = props;
	const classes = useStyles();
	const { addToast } = useToasts();
	const breadcrumbList = [
		{
			type: 'route',
			route: Routes.Studies.List,
			label: 'Lista kierunków'
		},
		{ label: 'Informacje o studiach' }
	];
	const [data, setData] = useState({
		title: { pl: '', en: '' },
		introduction: { pl: '', en: '' },
		description: { pl: '', en: '' },
	});
	const [files, setFiles] = useState({
		image: null,
		main: null
	});
	const [errors, setErrors] = useState(null);
	const [progressStatus, setProgressStatus] = useState(false);
	const Validators = {
		title: new Validator(Required),
		introduction: new Validator(Required),
		description: new Validator(Required),
	};
	const FileValidators = {
		image: new Validator(FileSizeLimit),
		main: new Validator(FileSizeLimit),
	};

	useEffect(() => {
		API.studies.getInfo().then(res => {
			if (!res?.data) return;
			setData(res.data);
			setFiles({ ...res.data?.files, image: res.data?.files?.image?.[0] });
		});
	}, []);

	const handleChange = e => {
		const target = e.target;
		const locale = target.dataset?.locale;
		const name = target.name;
		const value = target.value;

		setData(prev => {
			prev[name] = locale
				? { ...prev[name], [locale]: value }
				: value;
			return { ...prev }
		})
	}

	const handleFileChange = e => {
		const target = e.target;
		const name = target.name;
		const value = target.value;
		setFiles(prev => {
			prev[name] = value;
			return { ...prev }
		});
	}

	const handleSave = () => {
		let _errors = validate(data, Validators);
		let fileErrors = validate(files, FileValidators);
		setErrors({ ..._errors, file: fileErrors });
		if (_errors || fileErrors) return;
		setProgressStatus(true);

		API.studies.setInfo(data)
			.then(res => {
				API.files.upload(files, 1, 'study_information').then(res => {
					setProgressStatus(false);
					addToast(Messages.SuccessResponse, { autoDismissTimeout: 3000, autoDismiss: true, appearance: 'success' });
					setTimeout(() => {
						history.push(Routes.Studies.List);
					}, 300);
				});
			}).catch(err => setProgressStatus(false));
	}

	return (
		<>
			<Grid container justifyContent="space-between" >
				<BackToMain breadcrumbList={breadcrumbList} title={`Wróć do listy kierunków`} onClick={() => history.push(Routes.Studies.List)} />
			</Grid>
			<Title value={title} />
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<Card className={clsx(classes.card, classes.cardLeft)}>
						<Title value={`Dane podstawowe`} />
						<FormInput
							title={'Tytuł'}
							name={'title'}
							value={data.title}
							onChange={handleChange}
							error={errors?.title}
							translatable
						/>
						<Grid className={classes.label}>
							<label>Wstęp</label>
						</Grid>
						<Grid item xs={12}>
							<Html
								name={'introduction'}
								value={data.introduction}
								onChange={handleChange}
								error={errors?.introduction}
								translatable
							/>
						</Grid>
					</Card>

					<Card className={clsx(classes.card, classes.cardLeft)}>
						<Grid className={classes.label}>
							<label>Opis</label>
						</Grid>
						<Grid item xs={12}>
							<Html
								name={'description'}
								value={data.description}
								onChange={handleChange}
								error={errors?.description}
								translatable
							/>
						</Grid>
					</Card>

					<Card className={clsx(classes.card, classes.cardLeft)}>
						<Title value={`Załączniki`} />
						<FilesPicker
							name={'main'}
							value={files.main}
							onChange={handleFileChange}
							error={errors?.file?.main}
							multiple
						/>
					</Card>
				</Grid>
				<Grid item xs={3}>
					<Card className={classes.card}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<SaveButton
									onClick={handleSave}
									saving={progressStatus} />
							</Grid>

						</Grid>
					</Card>
					<Card>
						<Grid className={classes.label}>Obrazek wyróżniający</Grid>
						<ThumbnailPicker value={files.image} name={`image`} onChange={handleFileChange} editLabel="Edytuj obrazek" deleteLabel="Usuń obrazek" />
					</Card>
				</Grid>
			</Grid>
			<Progress status={progressStatus} />
		</>
	);
};

export default StudiesInfo;
