import React, { useState, useEffect } from 'react';
import {
	Grid, Card
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useToasts } from 'react-toast-notifications'
import moment from 'moment';

import Routes from 'router/Routes';
import API from 'apis/API';
import Validator, { Required, SiteUrl, ValidISOString, BeforeOrEqual, AfterOrEqual, RequiredIf, FileSizeLimit, validate } from 'utils/Validator';
import Messages from 'utils/Messages';

import BackToMain from 'components/Buttons/BackToMain';
import SaveButton from 'components/Buttons/SaveButton';
import DeleteButton from 'components/Buttons/DeleteButton';
import Progress from 'components/Main/Progress';
import Title from 'components/Text/Title';
import { Visible, VisibleOff } from 'components/Text/Visible';
import FormInput from 'components/Form/FormInput';
import Checkbox from 'components/Form/Checkbox';
import Html from 'components/Form/Html';
import Radio from 'components/Form/Radio';
import ThumbnailPicker from 'components/Form/ThumbnailPicker';
import DateInput from 'components/Form/DateInput';
import TimeInput from 'components/Form/TimeInput';
import Dropdown from 'components/Form/PlainInputs/Dropdown';
import Input from 'components/Form/PlainInputs/Input';
import FilesPicker from 'components/Form/FilesPicker';
import useGroupOptions from "../../hooks/useGroupOptions";

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 500,
        fontFamily: 'roboto',
        fontSize: '0.875em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
	card: {
		marginBottom: theme.spacing(4)
	},
	cardLeft: {
		paddingRight: theme.spacing(20)
	},
	label: {
		fontSize: 14,
		fontWeight: 700,
	},
	radio: {
		width: '100%',
		margin: 0,
		alignItems: 'center'
	},
}));

const EventsForm = props => {
	const { title, history } = props;
	const id = props.match.params.id;
	const classes = useStyles();
	const { addToast } = useToasts();
	const visibles = [
		{
			name: 1,
			label: <Visible />
		},
		{
			name: 0,
			label: <VisibleOff />
		},
	];
	const locations = [
		{
			id: 1,
			title: 'Online',
		},
		{
			id: 2,
			title: 'Miejsce',
		},
	];

	const [selectedLocation, setSelectedLocation] = useState(1);
	const [errors, setErrors] = useState(null);
	const [progressStatus, setProgressStatus] = useState(false);
	const [files, setFiles] = useState({
		image: null,
		main: null,
		promotion: null,
		gallery: null,
	});
    const groupOptions = useGroupOptions();
	const [groups, setGroups] = useState(null);
	const [data, setData] = useState({
		title: { pl: '', en: '' },
		is_promoted: false,
		show_in_calendar: false,
		site_url: '',
		description: { pl: '', en: '' },
		start_date: moment().format('YYYY-MM-DD HH:mm'),
		end_date: moment().format('YYYY-MM-DD HH:mm'),
		is_full_day: false,
		location_uri: '',
		location_address: '',
        visible_for: [],
		group_id: 1,
		is_visible: 1,
	});
	const breadcrumbList = [
		{
			type: 'route',
			route: Routes.Events.List,
			label: 'Wydarzenia'
		},
		{ label: data?.title?.pl }
	];
	const Validators = {
		title: new Validator(Required),
		site_url: new Validator(Required, SiteUrl),
		description: new Validator(Required),
		start_date: new Validator(Required, ValidISOString(), BeforeOrEqual('end_date', Messages.BeforeEndDate)),
		end_date: new Validator(RequiredIf('is_full_day', false, undefined, ''), ValidISOString(Messages.InvalidTime), AfterOrEqual('start_date', Messages.AfterStartDate)),
	};
	const FileValidators = {
		image: new Validator(FileSizeLimit),
		main: new Validator(FileSizeLimit),
		promotion: new Validator(FileSizeLimit),
		gallery: new Validator(FileSizeLimit),
	};

	useEffect(() => {
		API.events.groups().then(res => setGroups(res.data));
		if (!id) return;
		API.events.show(id).then(res => {
			if (!res?.data) return;
			let _data = {
				...res.data,
                visible_for: JSON.parse(res?.data?.visible_for),
				is_promoted: parseInt(res.data?.is_promoted),
				show_in_calendar: res.data?.show_in_calendar,
				is_full_day: parseInt(res.data?.is_full_day),
				group_id: parseInt(res.data?.group_id),
				is_visible: parseInt(res.data?.is_visible),
			}
			setData(_data);
			setFiles({ ...res.data?.files, image: res.data?.files?.image?.[0] });

			if (!_data.location_uri && _data.location_address) setSelectedLocation(2);
		})
	}, [id]);

	const handleChange = e => {
		const target = e.target;
		const locale = target.dataset?.locale;
		const name = target.name;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		value = target.type === 'radio' ? parseInt(value) : value;

		setData(prev => {
			prev[name] = locale
				? { ...prev[name], [locale]: value }
				: value;
			return { ...prev }
		});
	};

	const handleLocationChange = e => {
		let value = e.target.value
		setSelectedLocation(value);
		setData(prev => {
			if (value === 1) prev.location_address = '';
			else prev.location_uri = '';
			return { ...prev };
		});
	};

	const handleFileChange = e => {
		const target = e.target;
		const name = target.name;
		const value = target.value;
		setFiles(prev => {
			prev[name] = value;
			return { ...prev }
		});
	};

	const handleSave = () => {
		let _Validators = { ...Validators };
		if (data.location_uri) _Validators['location_uri'] = new Validator(SiteUrl);
		let _errors = validate(data, _Validators);
		let fileErrors = validate(files, FileValidators);
		setErrors({ ..._errors, file: fileErrors });
		if (_errors || fileErrors) return;

		setProgressStatus(true);
		(id ? API.events.update : API.events.create)({
            ...data,
            visible_for: JSON.stringify(data?.visible_for)
        }, id)
			.then(res => {
				const newId = res?.data?.id;
				if (!newId) {
					setProgressStatus(false);
					addToast(res.message, { autoDismissTimeout: 3000, autoDismiss: true, appearance: 'error' });
				}

				API.files.upload(files, newId, 'events').then(res => {
					setProgressStatus(false);
					addToast(Messages.SuccessResponse, { autoDismissTimeout: 3000, autoDismiss: true, appearance: 'success' });
					setTimeout(() => {
						history.push(Routes.Events.List);
					}, 300);
				});
			}).catch(err => setProgressStatus(false));
	};

	const handleDelete = () => {
		setProgressStatus(true);
		API.events.delete(id).then(res => {
			let message = res.data.message;
			API.files.delete(id, 'events').then(res => {
				setProgressStatus(false);
				addToast(message, { appearance: 'success', autoDismissTimeout: 3000, autoDismiss: true });
				setTimeout(() => {
					history.push(Routes.Events.List);
				}, 300);
			});
		});
	};

    console.log(data)

	return (
		<>
			<Grid container justifyContent="space-between" >
				<BackToMain breadcrumbList={breadcrumbList} title={`Wróć do listy wydarzeń`} onClick={() => history.push(Routes.Events.List)} />
			</Grid>
			<Title value={title} />
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<Card className={`${classes.card} ${classes.cardLeft}`}>
						<Title value={`Dane podstawowe`} />
						<FormInput
							title={'Tytuł aktualności'}
							name={'title'}
							value={data.title}
							onChange={handleChange}
							error={errors?.title}
							translatable
						/>
						<Grid container spacing={3}>
							<Grid item xs={12} md={4} />
							<Grid item xs={12} md={8}>
								<Checkbox
									name={'is_promoted'}
									title={'Wydarzenie wyróżnione'}
									value={data.is_promoted}
									onChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={3}>
							<Grid item xs={12} md={4} />
							<Grid item xs={12} md={8}>
								<Checkbox
									name={'show_in_calendar'}
									title={'Pokaż wydarzenie w kalendarzu'}
									value={data.show_in_calendar}
									onChange={handleChange}
								/>
							</Grid>
						</Grid>
						<FormInput
							title={'Wpis na stronie uczelni'}
							name={'site_url'}
							value={data.site_url}
							onChange={handleChange}
							error={errors?.site_url}
						/>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={4} className={classes.title}>
                                <label>Grupy odbiorcow</label>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Dropdown
                                    multiple
                                    customPlaceholder={'Wszystkie'}
                                    name={'visible_for'}
                                    value={data.visible_for || null}
                                    valueField={'value'}
                                    titleField={'label'}
                                    options={groupOptions}
                                    onChange={handleChange}
                                    renderValue={(selected) => {
                                        if (selected.length === 0)
                                            return <em>Wszystkie</em>;

                                        return selected.join(' | ');
                                    }}
                                />
                            </Grid>
                        </Grid>
					</Card>
					<Card className={`${classes.card} ${classes.cardLeft}`}>
						<Title value={`Opis`} />

						<Grid item xs={12}>
							<Html
								name={'description'}
								value={data.description}
								onChange={handleChange}
								error={errors?.description}
								translatable
							/>
						</Grid>
					</Card>
					<Card className={`${classes.card} ${classes.cardLeft}`}>
						<Title value={`Szczegóły wydarzenia`} />
						<Grid container spacing={3}>
							<Grid item xs={12} md={4} container alignItems="center"><label className={classes.label}>Data i czas</label></Grid>
							<Grid item xs={12} md={8} container>
								<Grid item xs={1} container justifyContent="center" style={{ margin: 'auto' }}><p>od</p></Grid>
								<Grid item xs={5} container>
									<Grid item xs={data.is_full_day ? 12 : 6}>
										<DateInput
											name={'start_date'}
											value={data.start_date}
											onChange={handleChange}
											error={errors?.start_date}
										/>
									</Grid>
									{!data.is_full_day &&
										<Grid item xs={6}>
											<TimeInput
												name={'start_date'}
												value={data.start_date}
												onChange={handleChange}
											/>
										</Grid>
									}
								</Grid>
								<Grid item xs={1} container justifyContent="center" style={{ margin: 'auto' }}><p>do</p></Grid>
								<Grid item xs={5} container>
									<Grid item xs={data.is_full_day ? 12 : 6}>
										<DateInput
											name={'end_date'}
											value={data.end_date}
											onChange={handleChange}
											error={errors?.end_date}
										/>
									</Grid>
									{!data.is_full_day &&
										<Grid item xs={6}>
											<TimeInput
												name={'end_date'}
												value={data.end_date}
												onChange={handleChange}
											/>
										</Grid>
									}
								</Grid>
							</Grid>
						</Grid>
						<Grid container spacing={3}>
							<Grid item xs={12} md={4} />
							<Grid item xs={12} md={8}>
								<Checkbox
									name={'is_full_day'}
									title={'Wydarzenie całodniowe'}
									value={data.is_full_day}
									onChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={3}>
							<Grid item xs={12} md={4} container alignItems="center">
								<label className={classes.label}>Lokalizacja</label>
							</Grid>
							<Grid item xs={12} md={8}>
								<Grid container spacing={3}>
									<Grid item xs={3}>
										<Dropdown
											name={'location'}
											value={selectedLocation}
											options={locations}
											onChange={handleLocationChange}
											error={errors?.location_uri && selectedLocation !== 1}
										/>
									</Grid>
									<Grid item xs={9}>
										<Input
											name={selectedLocation === 1 ? `location_uri` : `location_address`}
											value={selectedLocation === 1 ? data.location_uri : data.location_address}
											onChange={handleChange}
											error={selectedLocation === 1 && errors?.location_uri}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid container spacing={3}>
							<Grid item xs={12} md={4} container alignItems="center">
								<label className={classes.label}>Grupa odbiorców</label>
							</Grid>
							<Grid item xs={12} md={8}>
								{groups &&
									<Dropdown
										name={'group_id'}
										value={data.group_id}
										options={groups}
										onChange={handleChange}
									/>
								}
							</Grid>
						</Grid>
					</Card>
					<Card className={`${classes.card} ${classes.cardLeft}`}>
						<Title value={`Załączniki`} />
						<FilesPicker
							name={'main'}
							value={files.main}
							onChange={handleFileChange}
							error={errors?.file?.main}
							multiple
						/>
						<FilesPicker
							name={'promotion'}
							value={files.promotion}
							onChange={handleFileChange}
							title={`Film promujący`}
							buttonTitle={`Dodaj film`}
							error={errors?.file?.promotion}
							multiple
							accept={`video/*`}
						/>
						<FilesPicker
							name={'gallery'}
							value={files.gallery}
							onChange={handleFileChange}
							title={`Galeria zdjęć`}
							buttonTitle={`Dodaj galerię`}
							error={errors?.file?.gallery}
							multiple
							accept={`image/*`}
							isPreview
						/>
					</Card>
				</Grid>
				<Grid item xs={3}>
					<Card className={classes.card}>
						<Grid container spacing={2}>
							<Grid item xs={id ? 6 : 12}>
								<SaveButton
									onClick={handleSave}
									saving={progressStatus} />
							</Grid>
							{id &&
								<Grid item xs={6}>
									<DeleteButton
										title={Messages.Delete}
										handleDelete={handleDelete} />
								</Grid>
							}
						</Grid>
					</Card>
					<Card className={classes.card}>
						<Grid container spacing={2} alignItems="center">
							<Grid item xs={4} className={classes.label}>
								Wpis widoczny
							</Grid>
							<Grid item xs={8}>
								<Radio
									title="Wpis widoczny"
									name="is_visible"
									value={data.is_visible}
									onChange={handleChange}
									list={visibles}
									className={classes.radio}
								/>
							</Grid>
						</Grid>
					</Card>

					<Card>
						<Grid className={classes.label} style={{ marginBottom: 8 }}>Obrazek wyróżniający</Grid>
						<ThumbnailPicker value={files.image} name={`image`} onChange={handleFileChange} editLabel="Edytuj obrazek" deleteLabel="Usuń obrazek" />
					</Card>
				</Grid>
			</Grid>
			<Progress status={progressStatus} />
		</>
	);
};

export default EventsForm;
