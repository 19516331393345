import React, { useState, useEffect } from 'react';
import {
	Grid, Card
} from '@material-ui/core';
import { useToasts } from 'react-toast-notifications'
import clsx from 'clsx';

import Routes from "../../router/Routes";
import API from "../../apis/API";
import Validator, { Required, validate } from 'utils/Validator';
import Messages from 'utils/Messages';
import BackToMain from '../../components/Buttons/BackToMain';
import Title from '../../components/Text/Title';
import FormInput from '../../components/Form/FormInput';
import Html from "../../components/Form/Html";
import { makeStyles } from "@material-ui/styles";
import SaveButton from 'components/Buttons/SaveButton';
import Progress from 'components/Main/Progress';

const useStyles = makeStyles((theme) => ({
	card: {
		marginBottom: theme.spacing(4)
	},
	card_left: {
		paddingRight: theme.spacing(20)
	},
	label: {
		fontWeight: 500,
		fontFamily: 'roboto',
		fontSize: '0.875em',
		marginBlock: theme.spacing(2)
	},
}));

const HealthInfo = props => {
	const { title, history } = props;
	const classes = useStyles();
	const { addToast, removeAllToasts } = useToasts();
	const breadcrumbList = [
		{
			type: 'route',
			route: Routes.Health.Create,
			label: 'Zdrowie'
		},
		{ label: 'Badania indywidualne' }
	];
	const [data, setData] = useState({
		title: { pl: '', en: '' },
		introduction: { pl: '', en: '' },
	});

	const [errors, setErrors] = useState(null);
	const [saving, setSaving] = useState(false);

	const Validators = {
		title: new Validator(Required),
		introduction: new Validator(Required),
	};

	useEffect(() => {
		API.health.getInfo().then(res => {
			if (!res?.data) return;
			setData(res.data);
		});
	}, []);

	const handleChange = e => {
		const target = e.target;
		const locale = target.dataset?.locale;
		const name = target.name;
		const value = target.value;

		setData(prev => {
			prev[name] = locale
				? { ...prev[name], [locale]: value }
				: value;
			return { ...prev }
		})
	}

	const handleSave = () => {
		let _errors = validate(data, Validators);
		setErrors(_errors);
		if (_errors) return;
		setSaving(true);

		API.health.setInfo(data)
			.then(res => {
					setSaving(false);
					addToast(Messages.SuccessResponse, { autoDismissTimeout: 3000, autoDismiss: true, appearance: 'success' });
			}).catch(err => setSaving(false));
	}

	return (
		<>
			<Grid container justifyContent="space-between" >
				<BackToMain breadcrumbList={breadcrumbList} title={`Informacje o badaniach`} onClick={() => history.push(Routes.Health.Create)} />
			</Grid>
			<Title value={title} />
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<Card className={clsx(classes.card, classes.card_left)}>
						<Title value={`Twoje badania - Dane podstawowe`} />
						<FormInput
							title={'Tytuł'}
							name={'title'}
							value={data.title}
							onChange={handleChange}
							error={errors?.title}
							translatable
						/>
						<Grid className={classes.label}>
							<label>Wstęp</label>
						</Grid>
						<Grid item xs={12}>
							<Html
								name={'introduction'}
								value={data.introduction}
								onChange={handleChange}
								error={errors?.introduction}
								translatable
							/>
						</Grid>
					</Card>
				</Grid>
				<Grid item xs={3}>
					<Card className={classes.card}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<SaveButton
									onClick={handleSave}
									saving={saving} />
							</Grid>
						</Grid>
					</Card>
				</Grid>
			</Grid>
			<Progress status={saving}/>
		</>
	);
};

export default HealthInfo;
