const Constants = {
    Locales: ['pl', 'en'],

    DefaultStringLimit: 255,
	EmailPattern: new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i),
    LatitudePattern: new RegExp(/^((-)?(0|([1-9][0-9]*))(\.[0-9]+)?)$/),
    LongitudePattern: new RegExp(/^((-)?(0|([1-9][0-9]*))(\.[0-9]+)?)$/),
    FullHexCodePattern: new RegExp(/^#[0-9A-F]{6}$/i),
    // SiteUrlPattern: new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/),
    SiteUrlPattern: new RegExp(/^(https?|ftp|torrent|image|irc):\/\/(-\.)?([^\s\/?\.#-]+\.?)+(\/[^\s]*)?$/i),
    PhoneNumberPattern: new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im),
    DefaultFileSizeLimit: 20000000, //20MB
};

export default Constants;