import React, {useState, useEffect} from 'react';
import {
    Grid, Card
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {useToasts} from 'react-toast-notifications'

import Routes from 'router/Routes';
import API from 'apis/API';
import Validator, {Required, Latitude, LengthLimit, Longitude, SiteUrl, validate} from 'utils/Validator';
import Messages from 'utils/Messages';

import Progress from 'components/Main/Progress';
import Title from 'components/Text/Title';
import BackToMain from 'components/Buttons/BackToMain';
import SaveButton from 'components/Buttons/SaveButton';
import FillButton from 'components/Buttons/FillButton';
import DeleteButton from 'components/Buttons/DeleteButton';
import {DeleteIconButton} from 'components/Buttons/IconButtons';
import Html from 'components/Form/Html';
import FormInput from 'components/Form/FormInput';
import ThumbnailPicker from 'components/Form/ThumbnailPicker';

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(4)
    },
    cardLeft: {
        paddingRight: theme.spacing(20)
    },
    filledButton: {
        width: '100%',
        marginTop: theme.spacing(2)
    },
    subtitle: {
        fontSize: 14,
        fontWeight: 700,
        marginBottom: theme.spacing(1)
    },
    label: {
        paddingBlock: theme.spacing(1)
    },
    paths: {
        marginTop: theme.spacing(2)
    },
}));

const VirtualWalkForm = props => {
    const {title, history} = props;
    const id = props.match.params.id;
    const classes = useStyles();
    const {addToast} = useToasts()

    const [data, setData] = useState({
        title: {pl: '', en: ''},
        latitude: '',
        longitude: '',
        address: '',
        description: {pl: '', en: ''},
        main_path: '',
    });
    const breadcrumbList = [
        {
            type: 'route',
            route: Routes.VirtualWalk.List,
            label: 'Lista punktów'
        },
        {label: data?.title?.pl}
    ]
    const [files, setFiles] = useState({
        image: null,
        icon: null,
    })
    const [paths, setPaths] = useState([]);
    const [errors, setErrors] = useState(null);
    const [progressStatus, setProgressStatus] = useState(false);
    const Validators = {
        title: new Validator(Required),
        description: new Validator(Required),
        address: new Validator(Required, LengthLimit()),
        latitude: new Validator(Required, Latitude, LengthLimit(10)),
        longitude: new Validator(Required, Longitude, LengthLimit(11)),
        main_path: new Validator(SiteUrl),
    };

    useEffect(() => {
        if (!id) return;
        API.virtualWalk.show(id).then(res => {
            if (!res?.data) return;
            setData(res.data);

            setFiles(prev => ({
                ...prev,
                image: res.data?.files?.image?.[0],
                icon: res.data?.files?.icon?.[0],
            }));
            setPaths(res.data?.paths);
        });
    }, [id]);

    const handleChange = e => {
        const target = e.target;
        const locale = target.dataset?.locale;
        const name = target.name;
        const value = target.value;

        setData(prev => {
            prev[name] = locale
                ? {...prev[name], [locale]: value}
                : value;
            return {...prev}
        });
    }

    const handlePathChange = (e, i) => {
        const target = e.target;
        const locale = target.dataset?.locale;
        const name = target.name;
        const value = target.value;

        setPaths(prev => {
            prev[i] = {
                ...prev[i],
                [name]: name === 'name' ? {
                    ...prev[i][name],
                    [locale]: value
                } : value
            };
            return [...prev]
        });
    }

    const handleFileChange = e => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        setFiles(prev => {
            prev[name] = value;
            return {...prev}
        });
    }

    const formatPaths = () => {
        let _paths = [];
        paths.forEach(path => {
            if (path.name && path.path) _paths.push(path);
        });
        return _paths;
    }

    const handleSave = () => {
        let _errors = validate(data, Validators);
        setErrors(_errors);
        if (_errors) return;

        setProgressStatus(true);
        (id ? API.virtualWalk.update : API.virtualWalk.create)({...data, paths: formatPaths()}, id)
            .then(res => {
                const newId = res?.data?.id;
                if (!newId) {
                    setProgressStatus(false);
                    addToast(res.message, {autoDismissTimeout: 3000, autoDismiss: true, appearance: 'error'});
                }

                API.files.upload(files, newId, 'virtual_walk').then(res => {
                    setProgressStatus(false);
                    addToast(Messages.SuccessResponse, {
                        autoDismissTimeout: 3000,
                        autoDismiss: true,
                        appearance: 'success'
                    });
                    setTimeout(() => {
                        history.push(Routes.VirtualWalk.List);
                    }, 300);
                });
            }).catch(err => setProgressStatus(false));
    }

    const handleDelete = () => {
        API.virtualWalk.delete(id).then(res => {
            let message = res.data.message;
            API.files.delete(id, 'virtual_walk').then(res => {
                addToast(message, {appearance: 'success', autoDismissTimeout: 3000, autoDismiss: true});
                setTimeout(() => {
                    history.push(Routes.VirtualWalk.List);
                }, 300);
            });
        });
    }

    return (
        <>
            <Grid container justifyContent="space-between">
                <BackToMain breadcrumbList={breadcrumbList} title={`Wróć do listy punktów`}
                            onClick={() => history.push(Routes.VirtualWalk.List)}/>
            </Grid>
            <Title value={title}/>
            <Grid container spacing={3}>
                <Grid item xs={9}>
                    <Card className={`${classes.card} ${classes.cardLeft}`}>
                        <Title value={`Dane podstawowe`}/>
                        <FormInput
                            title={'Nazwa punktu'}
                            name={'title'}
                            value={data.title}
                            onChange={handleChange}
                            error={errors?.title}
                            translatable
                        />
                        <FormInput
                            title={'Szerokość geograficzna'}
                            name={'latitude'}
                            value={data.latitude}
                            type={'number'}
                            min={0}
                            max={90}
                            onChange={handleChange}
                            error={errors?.latitude}
                        />
                        <FormInput
                            title={'Wysokość geograficzna'}
                            name={'longitude'}
                            value={data.longitude}
                            type={'number'}
                            min={0}
                            max={90}
                            onChange={handleChange}
                            error={errors?.longitude}
                        />
                        <FormInput
                            title={'Adres'}
                            name={'address'}
                            value={data.address}
                            onChange={handleChange}
                            error={errors?.address}
                        />
                    </Card>
                    <Card className={`${classes.card} ${classes.cardLeft}`}>
                        <Title value={`Opis`}/>
                        <Grid item xs={12}>
                            <Html
                                name={'description'}
                                value={data.description}
                                onChange={handleChange}
                                error={errors?.description}
                                translatable
                            />
                        </Grid>
                    </Card>
                    <Card className={`${classes.card} ${classes.cardLeft}`}>
                        <Title value={`Wirtualny spacer`}/>
                        <FormInput
                            title={'Wskaż ścieżkę'}
                            name={'main_path'}
                            value={data.main_path}
                            onChange={handleChange}
                            error={errors?.main_path}
                        />
                        {paths && paths.map((path, i) => (
                            <Grid key={i} container alignItems="flex-start" className={classes.paths}>
                                <Grid item xs={1} className={classes.label}>{i + 1}.</Grid>
                                <Grid item xs={11}>
                                    <Grid container>
                                        <Grid item xs={11}>
                                            <FormInput
                                                title={'Wskaż ścieżkę'}
                                                name={'path'}
                                                value={path.path}
                                                onChange={e => handlePathChange(e, i)}
                                                error={errors?.paths}
                                            />
                                        </Grid>
                                        <Grid item xs={1} container justifyContent="flex-end">
                                            <DeleteIconButton hideModal onClick={() => {
                                                setPaths(prev => {
                                                    prev.splice(i, 1);
                                                    return [...prev];
                                                })
                                            }}/>
                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.paths}>
                                        <Grid item xs={11}>
                                            <FormInput
                                                translatable
                                                title={'Nazwa'}
                                                name={'name'}
                                                value={path.name}
                                                onChange={e => handlePathChange(e, i)}
                                                error={errors?.names}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid container>
                            <Grid item xs={3}>
                                <FillButton title={`Dodaj obiekt`} className={classes.filledButton}
                                            onClick={() => setPaths(prev => {
                                                prev.push({name: ''});
                                                return [...prev]
                                            })}/>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.card}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <SaveButton
                                    onClick={handleSave}
                                    saving={progressStatus}/>
                            </Grid>
                            <Grid item xs={6}>
                                <DeleteButton
                                    title={Messages.Delete}
                                    handleDelete={handleDelete}/>
                            </Grid>
                        </Grid>
                    </Card>

                    <Card className={classes.card}>
                        <Grid className={classes.subtitle}>Obrazek wyróżniający</Grid>
                        <ThumbnailPicker value={files.image} name={`image`} onChange={handleFileChange}
                                         editLabel="Dodaj obrazek" deleteLabel="Usuń obrazek"/>
                    </Card>

                    <Card>
                        <Grid className={classes.subtitle}>Ikona</Grid>
                        <ThumbnailPicker value={files.icon} name={`icon`} onChange={handleFileChange}
                                         editLabel="Dodaj ikonę" deleteLabel="Usuń obrazek"/>
                    </Card>
                </Grid>
            </Grid>
            <Progress status={progressStatus}/>
        </>
    );
};

export default VirtualWalkForm;
