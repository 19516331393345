import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  title: {
    fontFamily: 'roboto',
    marginBottom: theme.spacing(2),
    color: theme.palette.color,
    fontWeight: '700',
    fontSize: '1.1em',
  },
}));

const Title = props => {
  const { value, className } = props;
  const classes = useStyles();

  return (
    <div className={`${classes.title} ${className}`}>{value}</div>
  );
};

export default withRouter(Title);
