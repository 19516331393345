import React, { useState, useEffect } from 'react';
import {
	Grid, Card
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useToasts } from 'react-toast-notifications';
import clsx from 'clsx';

import API from 'apis/API';
import Validator, { Required, Email, PhoneNumber, LengthLimit, validate } from 'utils/Validator';
import Messages from 'utils/Messages';
import BackToMain from 'components/Buttons/BackToMain';
import SaveButton from 'components/Buttons/SaveButton';
import Progress from 'components/Main/Progress';
import Title from 'components/Text/Title';
import Html from 'components/Form/Html';
import FormInput from 'components/Form/FormInput';

const useStyles = makeStyles((theme) => ({
	card: {
		marginBottom: theme.spacing(4)
	},
	cardLeft: {
		paddingRight: theme.spacing(20)
	},
	label: {
		fontWeight: 500,
		fontSize: '0.875em',
		marginBottom: theme.spacing(1)
	},
	textarea: {
		marginBlock: theme.spacing(2)
	}
}));

const CommonInformation = props => {
	const classes = useStyles();
	const { addToast } = useToasts()
	const breadcrumbList = [
		{ label: 'Bilioteka tekst' }
	];
	const [data, setData] = useState({
		title: { pl: '', en: '' },
		description: { pl: '', en: '' },
		book_title: { pl: '', en: '' },
		book_description: { pl: '', en: '' },
		loan_title: { pl: '', en: '' },
		loan_description: { pl: '', en: '' },
		phone_number: '',
		email: '',
		address: ''
	});
	const [errors, setErrors] = useState(null);
	const [progressStatus, setProgressStatus] = useState(false);
	const Validators = {
		title: new Validator(Required, LengthLimit()),
		description: new Validator(Required),
		book_title: new Validator(Required, LengthLimit()),
		book_description: new Validator(Required),
		loan_title: new Validator(Required, LengthLimit()),
		loan_description: new Validator(Required),
		phone_number: new Validator(Required, PhoneNumber, LengthLimit()),
		email: new Validator(Required, Email, LengthLimit()),
		address: new Validator(Required, LengthLimit()),
	};

	useEffect(() => {
		API.commonInformation.show().then(res => {
			if (!res?.data) return;
			setData(res?.data);
		});
	}, []);

	const handleChange = e => {
		const target = e.target;
		const locale = target.dataset?.locale;
		const name = target.name;
		const value = target.value;

		setData(prev => {
			prev[name] = locale
				? { ...prev[name], [locale]: value }
				: value;
			return { ...prev }
		})
	}

	const handleSave = () => {
		let _errors = validate(data, Validators);
		setErrors(_errors);
		if (_errors) return;
		setProgressStatus(true);

		API.commonInformation.update(data).then(res => {
			setProgressStatus(false);
			addToast(Messages.SuccessResponse, { autoDismissTimeout: 3000, autoDismiss: true, appearance: 'success' });
		});
	}

	return (
		<>
			<Grid container justifyContent="space-between" >
				<BackToMain breadcrumbList={breadcrumbList} />
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={9}>
					<Card className={clsx(classes.card, classes.cardLeft)}>
						<Title value={`Dane podstawowe`} />
						<FormInput
							title={'Tytuł'}
							name={'title'}
							value={data.title}
							onChange={handleChange}
							error={errors?.title}
							translatable
						/>
						<div className={classes.textarea}>
							<div className={classes.label}>Tekst ponad zarezerwuj</div>
							<Html
								name={'description'}
								value={data.description}
								onChange={handleChange}
								error={errors?.description}
								className={classes.textarea}
								translatable
							/>
						</div>
						<FormInput
							title={'Zarezerwuj książke tekst'}
							name={'book_title'}
							value={data.book_title}
							onChange={handleChange}
							error={errors?.book_title}
							translatable
						/>
						<div className={classes.textarea}>
							<div className={classes.label}>Tekst poniżej zarezerwuj</div>
							<Html
								name={'book_description'}
								value={data.book_description}
								onChange={handleChange}
								error={errors?.book_description}
								translatable
							/>
						</div>
						<FormInput
							title={'Zobacz aktualne wypożyczenia tekst'}
							name={'loan_title'}
							value={data.loan_title}
							onChange={handleChange}
							error={errors?.loan_title}
							translatable
						/>

						<div className={classes.textarea}>
							<div className={classes.label}>Tekst poniżej wypożyczenia</div>
							<Html
								name={'loan_description'}
								value={data.loan_description}
								onChange={handleChange}
								error={errors?.loan_description}
								translatable
							/>
						</div>
						<FormInput
							title={'Telefon'}
							name={'phone_number'}
							value={data.phone_number}
							onChange={handleChange}
							error={errors?.phone_number}
						/>
						<FormInput
							title={'E-mail'}
							name={'email'}
							value={data.email}
							onChange={handleChange}
							error={errors?.email}
						/>
						<FormInput
							title={'Adres'}
							name={'address'}
							value={data.address}
							onChange={handleChange}
							error={errors?.address}
						/>
					</Card>
				</Grid>
				<Grid item xs={3}>
					<Card className={classes.card}>
						<SaveButton
							onClick={handleSave}
							saving={progressStatus} />
					</Card>
				</Grid>
			</Grid>
			<Progress status={progressStatus} />
		</>
	);
};

export default CommonInformation;
