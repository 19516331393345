import API from "./API";
import Storage from "../utils/Storage";

export const Interceptors = {
	register: () => Object.values(Interceptors).forEach(method => method.use?.()),

	References: {
		Logout: null,
		Token: null,
	},

	Logout: {
		use: () => {
			Interceptors.References.Logout = API.interceptors.response.use(null, error => {
				if (error.request?.status === 401) {
					Storage.remove('token');
				}

				return Promise.reject(error);
			});
		},
		eject: () => API.interceptors.response.eject(Interceptors.References.Logout),
	},
	Token: {
		use: () => {
			Interceptors.References.Token = API.interceptors.request.use(config => {
				const token = Storage.get('token');

				if (!!token) {
					config.headers = config.headers || {};
					config.headers.Authorization = 'Bearer ' + token;
				}

				return config;
			})
		},
		eject: () => API.interceptors.request.eject(Interceptors.References.Token),
	},
};
