import React from "react";
import API from "./apis/API";
import Routes from "router/Routes";
import { useHistory } from "react-router-dom";

const AppContainer = props => {
    const { children } = props;
    const history = useHistory();

    const items = [
        { id: 1, href: Routes.Students.List },
        { id: 2, href: Routes.News.List },
        { id: 3, href: Routes.Events.List },
        { id: 4, href: Routes.VirtualWalk.List },
        { id: 5, href: Routes.Trainings.List },
        { id: 6, href: Routes.Calendar.List },
        { id: 7, href: Routes.Questions.List },
        { id: 8, href: Routes.Studies.List },
        { id: 9, href: Routes.Health.Create },
        { id: 10, href: Routes.Social.Create },
        { id: 11, href: Routes.Accessibility.Create },
        { id: 12, href: Routes.Support.Create },
        { id: 13, href: Routes.Users.List, },
    ];


    React.useEffect(() => {
        const currentPage = items.find(item => history.location.pathname.search(item.href) > -1);
        let pathname = history.location.pathname;

        API.auth.validateToken().then(res => {
            let data = res.data;

            if (data.code !== 401) {
                let permissions = data.user.permissions;
                if (pathname !== '/profile' && !permissions.includes(currentPage.id)) {
                    history.goBack();
                }
                return;
            }

            history.push('/login');
        }).catch(error => {
            history.push('/login');
        });
    }, [history, items]);

    return <>{React.cloneElement(children)}</>;
}

export default AppContainer;