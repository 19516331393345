import React from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { TableCell, TableRow, Grid } from '@material-ui/core';

import API from 'apis/API';
import Routes from 'router/Routes';
import Messages from 'utils/Messages';

import PaginatedTable from 'components/Table/PaginatedTable';
import FillButton from 'components/Buttons/FillButton';
import { EditIconButton, DeleteIconButton } from 'components/Buttons/IconButtons';

const Studies = props => {
    const history = useHistory();
    const { addToast } = useToasts();
    const { title } = props;

    const columns = [
        {
            title: 'ID',
            name: 'id',
            width: 100
        },
        {
            title: 'Kierunek',
            name: 'group_assigment',
        },
        {
            title: 'Typ studiów',
            name: 'type_of_study',
        },
        {
            title: 'Akcje',
            name: 'action',
            width: 100
        },
    ];

    const buttons = [
        {
            component: FillButton,
            title: 'Dodaj kierunek',
            onClick: () => history.push(Routes.Studies.Create)
        },
        {
            component: FillButton,
            title: 'Informacje o studiach',
            onClick: () => history.push(Routes.Studies.Info)
        },
    ];

    const handleDelete = id => {
        API.studies.delete(id).then(res => {
            let message = res.data.message;
            API.files.delete(id, 'studies').then(res => {
                addToast(message, { appearance: 'success', autoDismissTimeout: 3000, autoDismiss: true });
            });
        });
    }

    const renderRow = (item, index) => {
        return(
            <TableRow key={index} >
                <TableCell>{item.id}</TableCell>
                <TableCell>{item?.programe}</TableCell>
                <TableCell>{''}</TableCell>
                <TableCell>
                    <Grid container>
                        <Grid item xs={6}>
                            <EditIconButton onClick={() => history.push(Routes.Studies.Edit(item.id))} />
                        </Grid>
                        <Grid item xs={6}>
                            <DeleteIconButton onClick={() => handleDelete(item.id)} title={Messages.Delete} />
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
        )
    };

    return (
        <PaginatedTable
            title={title}
            buttons={buttons}
            endpoint={API.studies.index}
            columns={columns}
            renderRow={renderRow}
        />
    );
};

export default Studies;
