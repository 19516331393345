const Routes = {
	Auth: {
		login: '/login',
	},
	Students: {
		List: '/students',
		Show: (id = ':id') => `/students/profile/${id}`,
	},
	News: {
		List: '/news',
		Edit: (id = ':id') => `/news/${id}/edit`,
		Create: '/news/create',
	},
	Events: {
		List: '/events',
		Edit: (id = ':id') => `/events/${id}/edit`,
		Create: '/events/create',
	},
	VirtualWalk: {
		List: '/virtual_walk',
		Edit: (id = ':id') => `/virtual_walk/${id}/edit`,
		Create: '/virtual_walk/create',
		Info: '/virtual_walk/information',
        MapSettings: '/virtual_walk/map'
	},
	Trainings: {
		List: '/trainings',
		Edit: (id = ':id') => `/trainings/${id}/edit`,
		Create: '/trainings/create',
	},
	Calendar: {
		List: '/calendar',
		Edit: (id = ':id') => `/calendar/${id}/edit`,
		Create: '/calendar/create',
	},
	Questions: {
		List: '/questions',
		Edit: (id = ':id') => `/questions/${id}/edit`,
		Create: '/questions/create',
		Info: '/questions/information',
	},
	Studies: {
		List: '/studies',
		Edit: (id = ':id') => `/studies/${id}/edit`,
		Create: '/studies/create',
		Info: '/studies/information',
	},
	Health: {
		Create: '/health',
		Info: '/health/information',
	},
	Social: {
		Create: '/social',
	},
	Accessibility: {
		Create: '/accessibility',
	},
	Support: {
		Create: '/support',
	},
	Users: {
		List: '/users',
		Create: '/users/create',
		Edit: (id = ':id') => `/users/${id}/edit`,
	},
	Profile: {
		Edit: '/profile'
	},
	CommonInformation: {
		Create: '/common_information'
	}
};

export default Routes;
